import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, Select, Tag } from "antd";
import { useState } from "react";
import { CloudinarySubfolders } from "../../../enums/cloudinary";
import { MutationKeys, QueryKeys } from "../../../enums/react-query";
import { countriesObj } from "../../../mock/country";
import { cloudinaryUpload } from "../../../services/app/app.service";
import { addCustomers } from "../../../services/customer/customer.service";
import { getDevices } from "../../../services/devices/devices.service";
import { useAppStore } from "../../../store/store";
import { SortOrder, TAddCustomer, TAddDevice } from "../../../types/types";
import { handleParams } from "../../../utils/helper";
import { imageUploadHandler } from "../../../utils/image-upload-handler";
import DevicesMultiSelect from "../../atoms/DevicesMultiSelect";
import ProfileImage from "../../atoms/ProfileImage";

const AddCustomerDraw = () => {
  const setIsDrawerOpen = useAppStore((state) => state.setIsDrawerOpen);
  const [form] = Form.useForm();
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageFormData, setImageFormData] = useState<FormData | undefined>();
  const queryClient = useQueryClient();
  const selectedDevices = Form.useWatch("devices", form);

  const {
    isLoading: fetchingDevicesIsLoading,
    error,
    data: devicesData,
  } = useQuery({
    queryKey: [
      QueryKeys.GETDEVICETABLEDATA,
      {
        order: SortOrder.DESC,
        field: "createdAt",
      },
      {
        field: "status",
        value: "available",
      },
      undefined,
      undefined,
    ],
    queryFn: async (params) => {
      const { sort, filter, pagination, search } = handleParams(params);

      const res = await getDevices({ sort, filter, pagination, search });
      return res.data;
    },
  });

  const { mutateAsync: uploadAvatar, isPending: isUploadPending } = useMutation(
    {
      mutationKey: [MutationKeys.CUSTOMERAVATARUPLOAD],
      mutationFn: async () => {
        const response = await cloudinaryUpload(imageFormData);
        const data = await response.json();
        return data.secure_url;
      },
    }
  );

  const { mutate: addCustomerMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.ADDCUSTOMER],
    mutationFn: (values: TAddCustomer) => addCustomers({ ...values }),
    onSuccess: (data) => {
      setIsDrawerOpen(false);
      queryClient.invalidateQueries({
        queryKey: [`${QueryKeys.GETCUSTOMERTABLEDATA}`],
      });
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const onFinish = async (values: TAddCustomer) => {
    let avatar = "";

    // upload avatar first if available
    if (imagePreviewUrl) {
      avatar = await uploadAvatar();
    }

    // update avatar in customer object
    const revampValues = {
      ...values,
      avatar,
    };

    // upload customer details
    addCustomerMutate(revampValues);
  };

  const handleDevicesChange = (value: TAddDevice[]) => {
    form.setFieldsValue({ devices: value });
  };

  const customizeRequiredMark = (label: any, prop: any) => (
    <>
      {prop?.required ? (
        <Tag color="error" className="Nunito">
          Required
        </Tag>
      ) : (
        <Tag color="warning" className="Nunito">
          Optional
        </Tag>
      )}
      {label}
    </>
  );

  return (
    <div className="w-full h-full">
      <div className="w-full bg-white h-[80px] md:h-[100px] flex items-center justify-center">
        <h5 className="text-[15px] 2xl:text-[20px] font-[600] text-[#6B7280] uppercase">
          create new customer
        </h5>
      </div>
      <div className="w-full bg-lightYellow h-[170px] flex items-center justify-center">
        <ProfileImage
          imagePreviewUrl={imagePreviewUrl}
          handleChange={(e) =>
            imageUploadHandler(
              e,
              CloudinarySubfolders.CUSTOMERS,
              setImageFormData,
              setImagePreviewUrl
            )
          }
          photoIsLoading={isUploadPending}
        />
      </div>
      <Form
        name="addCustomer"
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
        requiredMark={customizeRequiredMark}
        className="Nunito h-fit py-[25px] md:py-[39px] px-[30px] md:px-[58px] rounded-[8px] bg-white flex flex-col w-full gap-[24px] "
      >
        <Form.Item
          name="fullName"
          label="Full name"
          rules={[
            {
              required: true,
              message: "Please input users full name!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="E.g John Snow"
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              message: "Please input a valid email!",
            },
            {
              required: true,
              message: "Please input users email!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="E.g michealjackson@eazipower.com"
          />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: "Please select customer's country!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="Nunito h-[44px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[16px] !font-[300] !text-[#667085]"
            options={countriesObj}
          />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            {
              required: true,
              message: "Please select users gender!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a gender"
            filterOption={(input, option) =>
              (option?.label || "").toLowerCase().includes(input.toLowerCase())
            }
            className="Nunito h-[44px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[16px] !font-[300] !text-[#667085]"
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please input an address!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="E.g No. 1 Somewhere on the Planet"
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone number"
          rules={[
            {
              required: true,
              message: "Please input a phone number!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="E.g +234 810 000 0000"
          />
        </Form.Item>
        <Form.Item
          name="wallet"
          label="Wallet address"
          rules={[
            {
              required: false,
              message: "Please input a wallet address!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="E.g hjsdbibb36488sn92bc843bcgb87ebc"
          />
        </Form.Item>
        <Form.Item
          name="devices"
          label="Devices"
          rules={[
            {
              required: true,
              message: "Please add device(s)!",
            },
          ]}
        >
          <DevicesMultiSelect
            allDevices={devicesData?.payload || []}
            handleDevicesChange={handleDevicesChange}
            selectedDevices={selectedDevices}
          />
        </Form.Item>

        <div className="flex gap-[14px] items-center justify-end">
          <Button
            type="primary"
            htmlType="button"
            className="Nunito w-full max-w-[127px] h-[40px] flex items-center justify-center bg-[#EFEFEF] hover:!bg-[#EFEFEF] !text-[#6B7280] hover:!text-[#6B7280] hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
            loading={false}
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Nunito w-fit h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
              loading={isPending || isUploadPending}
            >
              Add Customer
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddCustomerDraw;
