import { Form, Input, Select } from "antd";
import { countriesObj } from "../../mock/country";

const DrawerTextStack = ({
  heading,
  value,
  isLoading,
  placement,
  isEditMode,
  editModeData,
}: {
  heading: string;
  value: string;
  isLoading: boolean;
  placement: "left" | "right" | "center";
  isEditMode?: boolean;
  editModeData?: { placeholder: string; field: string };
}) => {
  return (
    <div
      className={`flex flex-col ${placement === "center" ? "items-center text-center" : placement === "left" ? "items-left text-left" : "items-right text-right"}`}
    >
      {isLoading ? (
        <div className="sk_bg w-[80px] h-[15px] mb-2"></div>
      ) : (
        <h5 className="text-[14px] 2xl:text-[16px] font-[500] text-[#9CA3AF]">
          {heading}
        </h5>
      )}
      {isLoading ? (
        <div className="sk_bg w-[120px] h-[15px]"></div>
      ) : isEditMode && heading === "Country" ? (
        <Form.Item
          name="country"
          rules={[
            {
              required: true,
              message: "Please select customer's country!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            className="Nunito h-[26px] w-[130px] max-w-[130px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[12px] !font-[300] !text-[#667085]"
            options={countriesObj}
          />
        </Form.Item>
      ) : isEditMode ? (
        <>
          <Form.Item
            name={editModeData?.field}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
            className="minEdit"
          >
            <Input
              className="Nunito h-[20px] w-[140px] !py-[8px] !px-[8px] bg-[#ffffff] border-x-[0px] border-t-[0px] border-b-solid border-b-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[0px] text-[14px] 2xl:text-[16px] font-[500] !text-[#000000E0] "
              placeholder={editModeData?.placeholder}
              readOnly={!isEditMode}
            />
          </Form.Item>
        </>
      ) : (
        <p className="text-[14px] 2xl:text-[16px] font-[300] text-black mt-[-2px] break-all">
          {value}
        </p>
      )}
    </div>
  );
};

export default DrawerTextStack;
