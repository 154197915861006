import { Select, Tag } from "antd";
import { useEffect, useRef } from "react";
import { TAddDevice, TDeviceData } from "../../types/types";
import { DeviceIconSvg } from "./svg";

const { Option } = Select;

const tagRender = (props: {
  label: any;
  value: any;
  closable: any;
  onClose: any;
}) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <div>
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
        className="hidden"
      >
        {label}
      </Tag>
      {/* <p className="DeviceMultipleSelectValue hidden">1 Device Selected</p> */}
    </div>
  );
};

const DevicesMultiSelect = ({
  allDevices,
  handleDevicesChange,
  selectedDevices,
}: {
  allDevices: TDeviceData[];
  handleDevicesChange: (value: TAddDevice[]) => void;
  selectedDevices: TAddDevice[];
}) => {
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      const tagCon = selectRef.current?.getElementsByClassName(
        "ant-select-selection-overflow-item"
      )[0];
      if (selectedDevices?.length > 0) {
        tagCon.innerHTML = `Selected ${selectedDevices?.length} Device(s)`;
      }
    }
  }, [selectedDevices]);

  return (
    <div ref={selectRef}>
      <Select
        showSearch
        mode="multiple"
        placeholder="Select device(s)"
        onChange={handleDevicesChange}
        tagRender={tagRender}
        filterOption={(input, option) => {
          return (option?.key ?? "").includes(input);
        }}
        rootClassName="DeviceSelect"
        className=" Nunito h-[44px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[16px] !font-[300] !text-[#667085]"
      >
        {allDevices.map((datum, index) => {
          return (
            <Option
              key={datum.serialNumber}
              value={datum._id}
              className="!p-2 "
            >
              <div className="flex w-full items-center gap-[5px]">
                <DeviceIconSvg className="h-[18px] mb-[2px]" />
                {datum.serialNumber}
              </div>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default DevicesMultiSelect;
