import gql from "graphql-tag";

export const FETCH_DEVICES = gql`
  query FetchDevices($params: QueryParamsInput!) {
    data: fetchDevices(params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_DEVICE = gql`
  query FetchDevice($deviceId: String!) {
    data: fetchDevice(deviceId: $deviceId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const ADD_DEVICE = gql`
  mutation AddDevice($addDeviceInput: AddDeviceInput!) {
    data: addDevice(addDeviceInput: $addDeviceInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const ADD_DEVICES = gql`
  mutation AddDevices($addDevicesInput: [AddDeviceInput!]!) {
    data: addDevices(addDevicesInput: $addDevicesInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const DELETE_DEVICES = gql`
  mutation DeleteDevices($deviceIds: [String!]!) {
    data: deleteDevices(deviceIds: $deviceIds) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation EditDevice($deviceId: String!, $editDeviceInput: EditDeviceInput!) {
    data: editDevice(deviceId: $deviceId, editDeviceInput: $editDeviceInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const LINK_DEVICE = gql`
  mutation linkDeviceToCustomer($customerId: String!, $deviceId: String!) {
    data: linkDeviceToCustomer(customerId: $customerId, deviceId: $deviceId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const UNLINK_DEVICE = gql`
  mutation unlinkDeviceToCustomer($customerId: String!, $deviceId: String!) {
    data: unlinkDeviceToCustomer(customerId: $customerId, deviceId: $deviceId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_DEVICE_COUNT = gql`
  query FetchTotalDeviceCount {
    data: fetchTotalDeviceCount {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_SOLD_OUT_DEVICE_COUNT = gql`
  query FetchSoldOutDevicesCount {
    data: fetchSoldOutDevicesCount {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_DEVICE_BRANDS = gql`
  query FetchDeviceBrands {
    data: fetchDeviceBrands {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_DEVICE_MODEL_BY_BRAND = gql`
  query FetchModelsByBrand($brandId: String!) {
    data: fetchModelsByBrand(brandId: $brandId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_ALL_DEVICE_MODELS = gql`
  query FetchDeviceModels {
    data: fetchDeviceModels {
      alert {
        type
        message
      }
      payload
    }
  }
`;
