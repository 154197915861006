import gql from "graphql-tag";

export const FETCH_PAYMENT_PLANS = gql`
  query FetchPaymentPlans($params: QueryParamsInput!) {
    data: fetchPaymentPlans(params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_PAYMENT_PLAN = gql`
  query FetchPaymentPlan($paymentPlanId: String!) {
    data: fetchPaymentPlan(paymentPlanId: $paymentPlanId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_PAYMENT_BONUSES = gql`
  query FetchPaymentBonuses {
    data: fetchPaymentBonuses {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const CREATE_PAYMENT_PLAN = gql`
  mutation CreatePaymentPlans(
    $createPaymentPlanInput: CreatePaymentPlanInput!
  ) {
    data: createPaymentPlan(createPaymentPlanInput: $createPaymentPlanInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const UPDATE_PAYMENT_PLAN = gql`
  mutation UpdatePaymentPlans(
    $paymentPlanId: String!
    $updatePaymentPlanInput: UpdatePaymentPlanInput!
  ) {
    data: updatePaymentPlan(
      paymentPlanId: $paymentPlanId
      updatePaymentPlanInput: $updatePaymentPlanInput
    ) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const DELETE_PAYMENT_PLANS = gql`
  mutation DeletePaymentPlans($paymentPlanIds: [String!]!) {
    data: deletePaymentPlans(paymentPlanIds: $paymentPlanIds) {
      alert {
        type
        message
      }
      payload
    }
  }
`;
