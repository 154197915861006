import { Button } from "antd";
import { GoUnlink } from "react-icons/go";
import { IoIosPaperPlane } from "react-icons/io";
import { TCustomerData, TDeviceData } from "../../types/types";
import DrawerTextStack from "../atoms/DrawerTextStack";
import { DeviceIconSvg, USDCFlagSvg } from "../atoms/svg";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useAppStore } from "../../store/store";

const CustomerDeviceBox = ({
  isLoading,
  data,
  customerData,
  allDevices,
}: {
  isLoading: boolean;
  data: TDeviceData;
  customerData: TCustomerData;
  allDevices: TDeviceData[];
}) => {
  const setModalIsOpen = useAppStore((state) => state.setModalIsOpen);
  const setModalType = useAppStore((state) => state.setModalType);
  const setModalData = useAppStore((state) => state.setModalData);
  const setModalDetails = useAppStore((state) => state.setModalDetails);

  const [percentage, setPercentage] = useState(0);
  const getPercentage = () => {
    const perc =
      ((data?.amountPaid as number) / (data?.totalCost as number)) * 100;

    return perc;
  };

  useEffect(() => {
    setPercentage(getPercentage());
  }, [data]);
  return (
    <div className="flex pb-[14px] border-b-[1px] border-b-[#E5E7EB] items-center justify-between w-full ">
      <div className="flex items-center gap-[14px] w-full  ">
        <div className="bg-yellow rounded-full h-[46px] w-[46px] flex items-center justify-center ">
          <DeviceIconSvg className="" />
        </div>
        <div className="flex-1">
          <div className="flex items-center">
            <div className="flex-1">
              <DrawerTextStack
                placement={"left"}
                isLoading={isLoading}
                heading={`S/N: ${data?.serialNumber}` || ""}
                value={`${data?.paymentPlan?.planName} Plan` || ""}
              />
            </div>
            {!isLoading && (
              <Button
                type="text"
                htmlType="button"
                icon={<GoUnlink className="text-[12px]" />}
                className={`Nunito w-fit px-[8px] py-[4px] h-[32px] flex items-center justify-center   hover:opacity-[0.8] font-[500] text-[10px] 2xl:text-[12px] ${(data?.amountPaid as number) > 0 ? "!border-[#D0D5DD] !bg-[#FFFFFF] hover:!bg-white !text-[#6B7280] hover:!text-[#6B7280]" : "!border-darkRed !bg-red  hover:!bg-red !text-[#ffffff] hover:!text-[#ffffff]"} !border-[1px] rounded-[8px]  `}
                loading={false}
                onClick={() => {
                  if ((data?.amountPaid as number) > 0) {
                    toast.warning(
                      `This customer has already started paying for this device so it can't be unlinked`
                    );
                  } else if (allDevices.length <= 1) {
                    toast.warning(
                      `at least (1) device has to be connected to a customer`
                    );
                  } else {
                    setModalType("unLinkDevice");
                    setModalIsOpen(true);
                    setModalDetails(customerData);
                    setModalData([data._id]);
                  }
                  // action();
                }}
              >
                Unlink
              </Button>
            )}
          </div>
          {isLoading ? (
            <div className="sk_bg w-full h-[8px] mt-2"></div>
          ) : (
            <div className="rounded-[4px] bg-[#F2F4F7] overflow-hidden h-[8px] w-full flex items-start mt-2">
              <div
                style={{
                  width: `${percentage}%`,
                  transition: "all 0.3s ease",
                }}
                className={` w-[${percentage}%] h-full rounded-[4px] bg-darkYellow transition-all duration-[300ms] `}
              ></div>
            </div>
          )}
          <div className="flex items-center justify-between mt-[2px]">
            {isLoading ? (
              <div className="sk_bg w-[85px] h-[16px] mt-[4px]"></div>
            ) : (
              <h5 className="text-[10px] 2xl:text-[12px] font-[700] text-[#475467] text-end flex items-center justify-end gap-[4px]">
                <USDCFlagSvg className="h-[12px] w-[12px]" />
                {data?.amountPaid} / {data?.totalCost} USDC
              </h5>
            )}
            {isLoading ? (
              <div className="sk_bg w-[130px] h-[16px] mt-[4px]"></div>
            ) : (
              <h5 className="text-[12px] 2xl:text-[14px] font-[500] text-[#475467] text-end flex items-center justify-end gap-[4px]">
                <IoIosPaperPlane className="text-darkYellow" />
                Payment success rate
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDeviceBox;
