import { GraphQLClient, request } from "graphql-request";
import { AccessToken } from "../../enums/access-token";
import { PublicRoutes } from "../../types/Routes";
import { DefaultResponse } from "../../types/default-response.dto";
import {
  CHANGE_EMAIL_FROM_RESET_EMAIL,
  CHANGE_PASSWORD_FROM_FORGOT_PASSWORD,
  RESEND_OTP_MUTATION,
  SEND_FORGOT_PASSWORD_LINK,
  SIGN_IN_MUTATION,
  VERIFY_OTP_MUTATION,
} from "./auth.queries";

export const signIn = async (
  email: string,
  password: string
): Promise<DefaultResponse> =>
  request(process.env.REACT_APP_BACKEND_API!, SIGN_IN_MUTATION, {
    signInInput: {
      email,
      password,
    },
  });

export const verifyOtp = async (
  email: string,
  otpCode: string
): Promise<DefaultResponse> =>
  request(process.env.REACT_APP_BACKEND_API!, VERIFY_OTP_MUTATION, {
    email,
    otpCode,
  });

export const resendOtp = async (email: string): Promise<DefaultResponse> =>
  request(process.env.REACT_APP_BACKEND_API!, RESEND_OTP_MUTATION, {
    email,
  });

export const forgotPassword = async (email: string): Promise<DefaultResponse> =>
  request(process.env.REACT_APP_BACKEND_API!, SEND_FORGOT_PASSWORD_LINK, {
    email,
  });

export const resetPassword = async (
  password: string,
  token: string
): Promise<DefaultResponse> => {
  const authClient = new GraphQLClient(process.env.REACT_APP_BACKEND_API!, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return authClient.request(CHANGE_PASSWORD_FROM_FORGOT_PASSWORD, {
    password,
  });
};

export const changeEmail = async (
  email: string,
  token: string
): Promise<DefaultResponse> => {
  const authClient = new GraphQLClient(process.env.REACT_APP_BACKEND_API!, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return authClient.request(CHANGE_EMAIL_FROM_RESET_EMAIL, {
    newEmail: email,
  });
};

export const signOut = () => {
  localStorage.removeItem(AccessToken.KEY);
  localStorage.clear();
  window.location.href = PublicRoutes.SIGNIN;
};
