import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, InputNumber, Select, Tag } from "antd";
import { useState } from "react";
import { MutationKeys, QueryKeys } from "../../../enums/react-query";
import { getAvailableBalances } from "../../../services/dashboard/dashboard.service";
import { initWithdrawal } from "../../../services/withdrawal/withdrawal.service";
import { useAppStore } from "../../../store/store";
import {
  TAccBalanceData,
  TFiatAccountSub,
  TUsdcAccountSub,
  TWithdrawalInput,
} from "../../../types/types";
import AccountRadio from "../../atoms/AccountRadio";

const WithdrawDraw = () => {
  const setIsDrawerOpen = useAppStore((state) => state.setIsDrawerOpen);
  const { Option } = Select;

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [activeFundType, setActiveFundType] = useState<"fiat" | "usdc">("fiat");
  const [availableFiatAccounts, setAvailableFiatAccounts] = useState<
    TAccBalanceData[]
  >([]);
  const [availableUsdcAccounts, setAvailableUsdcAccounts] = useState<
    TAccBalanceData[]
  >([]);

  const fiatWithdrawalAmount = Form.useWatch("fiatAmount", form);
  const usdcWithdrawalAmount = Form.useWatch("usdcAmount", form);
  const selectedFiatAccount = Form.useWatch("fiatAccount", form);
  const selectedUsdcAccount = Form.useWatch("usdcAccount", form);

  const [selectedFiatAccountBalance, setSelectedFiatAccountBalance] = useState<
    number | undefined
  >(undefined);
  const [selectedFiatAccountSymbol, setSelectedFiatAccountSymbol] =
    useState<string>("");

  const [selectedUsdcAccountBalance, setSelectedUsdcAccountBalance] = useState<
    number | undefined
  >(undefined);
  const [selectedUsdcAccountSymbol, setSelectedUsdcAccountSymbol] =
    useState<string>("");

  const changeActiveFundType = (value: "fiat" | "usdc") => {
    setActiveFundType(value);
  };

  const { isLoading, data } = useQuery({
    queryKey: [QueryKeys.GETAVAILABLEBALANCES],
    queryFn: async () => {
      const res = await getAvailableBalances();
      const data = res.data.payload as TAccBalanceData[];
      //get active Usdc and fiat accounts
      const activeUsdcAccounts = data.filter((datum) => {
        return datum.account?.accountType === "usdc";
      });
      setAvailableUsdcAccounts(activeUsdcAccounts);
      const activeFiatAccounts = data.filter((datum) => {
        return datum.account?.accountType === "fiat";
      });
      setAvailableFiatAccounts(activeFiatAccounts);

      return res.data;
    },
  });

  const { mutate: initWithdrawalMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.INITWITHDRAWAL],
    mutationFn: (values: TWithdrawalInput) => initWithdrawal(values),
    onSuccess: (data) => {
      setIsDrawerOpen(false);
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETDISTRIBUTORFIATACCOUNTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETDISTRIBUTORUSDCACCOUNTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETAVAILABLEBALANCES],
      });
    },
  });

  const onWithdrawFinish = (values: {
    fiatAccount: string;
    usdcAccount: string;
    bank: string;
    accNumber: string;
    accName: string;
    chain: string;
    publicKey: string;
    usdcAmount: number;
    fiatAmount: number;
  }) => {
    if (activeFundType === "fiat") {
      initWithdrawalMutate({
        account: values.fiatAccount,
        type: activeFundType,
        amount: values.fiatAmount,
      });
    } else if (activeFundType === "usdc") {
      initWithdrawalMutate({
        account: values.usdcAccount,
        type: activeFundType,
        amount: values.usdcAmount,
      });
    }
  };

  const handleSelectFiatAccount = (e: string) => {
    const filterSelectedAccountFromAllFiats = availableFiatAccounts.filter(
      (datum) => {
        return datum.account?._id === e;
      }
    );
    const accountData = filterSelectedAccountFromAllFiats[0].account
      ?.accountData as TFiatAccountSub;

    form.setFieldsValue({
      accName: accountData.accName,
      accNumber: accountData.accNumber,
      bank: accountData.bank,
    });
    setSelectedFiatAccountBalance(
      Number(filterSelectedAccountFromAllFiats[0].availableBalance.toFixed(2))
    );

    setSelectedFiatAccountSymbol(
      filterSelectedAccountFromAllFiats[0].currencySymbol
    );
  };

  const handleSelectUsdcAccount = (e: string) => {
    const filterSelectedAccountFromAllUsdcs = availableUsdcAccounts.filter(
      (datum) => {
        return datum.account?._id === e;
      }
    );
    const accountData = filterSelectedAccountFromAllUsdcs[0].account
      ?.accountData as TUsdcAccountSub;

    form.setFieldsValue({
      chain: accountData.chain,
      publicKey: accountData.publicKey,
    });
    setSelectedUsdcAccountBalance(
      Number(filterSelectedAccountFromAllUsdcs[0].availableBalance.toFixed(2))
    );
    setSelectedUsdcAccountSymbol(
      filterSelectedAccountFromAllUsdcs[0].currencySymbol
    );
  };

  const customizeRequiredMark = (label: any, prop: any) => (
    <>
      {prop?.required ? (
        <Tag color="error" className="Nunito">
          Required
        </Tag>
      ) : (
        <Tag color="warning" className="Nunito">
          optional
        </Tag>
      )}
      {label}
    </>
  );

  return (
    <div className="w-full h-full">
      <div className="w-full bg-yellow h-[80px] md:h-[100px] flex items-center justify-center">
        <h5 className="text-[15px] 2xl:text-[20px] font-[600] text-darkYellow uppercase">
          Withdrawal Funds
        </h5>
      </div>
      <div className="selectFundsType mt-[20px] px-[30px] md:px-[58px] flex flex-col gap-[3px]">
        <p className="text-[12px] font-[300] text-[#667085]">Funds type:</p>
        <div className="flex gap-4">
          <AccountRadio
            value="fiat"
            activeFundType={activeFundType}
            changeActiveFundType={changeActiveFundType}
          />
          <AccountRadio
            value="usdc"
            activeFundType={activeFundType}
            changeActiveFundType={changeActiveFundType}
          />
        </div>
        <div className="w-full h-[1px] bg-[#E6EAEE] "></div>
      </div>

      <Form
        name="withdrawal"
        form={form}
        initialValues={{}}
        onFinish={onWithdrawFinish}
        // onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
        requiredMark={customizeRequiredMark}
        className="Nunito h-fit pt-[15px] pb-[15px] md:pb-[29px] px-[30px] md:px-[58px] rounded-[8px] bg-white flex flex-col w-full gap-[24px] "
      >
        {activeFundType === "fiat" ? (
          <>
            <Form.Item
              name="fiatAccount"
              label="Receiving Account"
              className="flex-1"
              tooltip="Select the attached active fiat account you would like to withdraw to. if your bank account is attached to your distributor account but you can't find it here, please check if it's set to active. "
              rules={[
                {
                  required: true,
                  message: "Please select an account!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select an account"
                filterOption={(input, option) =>
                  ((option?.value as string) || "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={isLoading}
                onChange={handleSelectFiatAccount}
                className="Nunito h-[44px] bg-[#F9FAFB] !border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[16px] !font-[300] !text-[#667085]"
              >
                {availableFiatAccounts?.map(
                  (datum: TAccBalanceData, index: number) => {
                    return (
                      <Option
                        key={datum.account?._id}
                        value={datum.account?._id}
                        className="!p-2 "
                      >
                        <div className="flex w-full items-center gap-[5px] truncate">
                          <span className="w-[20px] h-[20px] rounded-full overflow-hidden mr-[2px]">
                            <img
                              className="w-full h-full"
                              src={
                                (datum.account?.accountData as TFiatAccountSub)
                                  .currency.flag
                              }
                              alt="flag"
                            />
                          </span>
                          {
                            (datum.account?.accountData as TFiatAccountSub)
                              .accName
                          }{" "}
                          -{" "}
                          {(datum.account?.accountData as TFiatAccountSub).bank}
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="bank"
              label="Bank Name"
              rules={[
                {
                  required: true,
                  message: "Please input a Bank Name!",
                },
              ]}
            >
              <Input
                className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="E.g Sapa Bank"
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="accNumber"
              label="Account Number"
              rules={[
                {
                  required: true,
                  message: "Please input an Account Number!",
                },
              ]}
            >
              <Input
                className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="E.g 0000000000"
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="accName"
              label="Account Name"
              rules={[
                {
                  required: true,
                  message: "Please input an Account Name!",
                },
              ]}
            >
              <Input
                className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="E.g John Doe"
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="fiatAmount"
              label="Withdrawal Amount"
              rules={[
                {
                  required: true,
                  message: "Please input a withdrawal amount!",
                },
              ]}
            >
              <InputNumber
                min={0}
                max={selectedFiatAccountBalance}
                className="Nunito w-full h-[44px] flex items-center !px-[4px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] !font-[300] !text-[#667085] "
                placeholder="Withdrawal amount"
              />
            </Form.Item>
            <div className="flex items-center justify-end -mt-[22px]">
              {selectedFiatAccountBalance && (
                <p className="text-darkYellow text-[11px] font-[500] align-right">
                  Avail Bal: {selectedFiatAccountSymbol}
                  {selectedFiatAccountBalance.toLocaleString()}
                </p>
              )}
            </div>
          </>
        ) : activeFundType === "usdc" ? (
          <>
            <Form.Item
              name="usdcAccount"
              label="Receiving Account"
              className="flex-1"
              tooltip="Select the attached active usdc account you would like to withdraw to. if your wallet is attached to your distributor account but you can't find it here, please check if it's set to active. "
              rules={[
                {
                  required: true,
                  message: "Please select an account!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select an account"
                filterOption={(input, option) =>
                  ((option?.value as string) || "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                loading={isLoading}
                onChange={handleSelectUsdcAccount}
                className="Nunito h-[44px] bg-[#F9FAFB] !border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[16px] !font-[300] !text-[#667085]"
              >
                {availableUsdcAccounts?.map(
                  (datum: TAccBalanceData, index: number) => {
                    return (
                      <Option
                        key={datum.account?._id}
                        value={datum.account?._id}
                        className="!p-2 "
                      >
                        <div className="flex w-full items-center gap-[5px] truncate">
                          <span className="w-[20px] h-[20px] rounded-full overflow-hidden mr-[2px]">
                            <img
                              className="w-full h-full"
                              src={
                                (datum.account?.accountData as TUsdcAccountSub)
                                  .currency.flag
                              }
                              alt="flag"
                            />
                          </span>
                          {
                            (datum.account?.accountData as TUsdcAccountSub)
                              .chain
                          }{" "}
                          -{" "}
                          {
                            (datum.account?.accountData as TUsdcAccountSub)
                              .publicKey
                          }
                        </div>
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="chain"
              label="Chain"
              rules={[
                {
                  required: true,
                  message: "Please input a chain!",
                },
              ]}
            >
              <Input
                className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="E.g Stellar"
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="publicKey"
              label="wallet address"
              rules={[
                {
                  required: true,
                  message: "Please input wallet address!",
                },
              ]}
            >
              <Input
                className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="E.g John Doe"
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="usdcAmount"
              label="Withdrawal Amount"
              rules={[
                {
                  required: true,
                  message: "Please input a withdrawal amount!",
                },
              ]}
            >
              <InputNumber
                min={0}
                max={selectedUsdcAccountBalance}
                className="Nunito w-full h-[44px] flex items-center !px-[4px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] !font-[300] !text-[#667085] "
                placeholder="Withdrawal amount"
              />
            </Form.Item>
            <div className="flex items-center justify-end -mt-[22px]">
              {selectedUsdcAccountBalance && (
                <p className="text-darkYellow text-[11px] font-[500] align-right">
                  Avail Bal: {selectedUsdcAccountSymbol}
                  {selectedUsdcAccountBalance.toLocaleString()}
                </p>
              )}
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="flex gap-[14px] items-center justify-end">
          <Button
            type="primary"
            htmlType="button"
            className="Nunito w-full max-w-[127px] h-[40px] flex items-center justify-center bg-[#EFEFEF] hover:!bg-[#EFEFEF] !text-[#6B7280] hover:!text-[#6B7280] hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
            loading={false}
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Nunito w-fit min-w-[120px] h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow disabled:hover:!bg-lightGrey hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
              loading={isPending}
              disabled={
                activeFundType === "fiat"
                  ? !selectedFiatAccount || !fiatWithdrawalAmount
                  : !selectedUsdcAccount || !usdcWithdrawalAmount
              }
            >
              Withdraw
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default WithdrawDraw;
