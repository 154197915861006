import { DefaultResponse } from "../../types/default-response.dto";
import { TParams, TWithdrawalInput } from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  FETCH_WITHDRAWAL,
  FETCH_WITHDRAWALS,
  INITIATE_WITHDRAWAL,
  REPORT_WITHDRAWAL,
} from "./withdrawal.queries";

export const initWithdrawal = async (
  data: TWithdrawalInput
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(INITIATE_WITHDRAWAL, {
    withdrawalRequestInput: {
      ...data,
    },
  });
};

export const getWithdrawals = async (
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_WITHDRAWALS, {
    params: { ...params },
  });
};

export const getWithdrawal = async (
  withdrawalId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_WITHDRAWAL, {
    withdrawalId,
  });
};

export const reportWithdrawal = async (
  withdrawalId: string,
  message: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(REPORT_WITHDRAWAL, {
    withdrawalId,
    message,
  });
};
