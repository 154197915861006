import { Button, Form, FormInstance, InputNumber } from "antd";
import { SetStateAction, useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import ClauseSingleBox from "./ClauseSingleBox";
import { TClause } from "../../types/types";
import { TBonusTypesDrop } from "../../types/payment-bonuses.types";

const ClausesBox = ({
  isEditMode,
  form,
  options,
}: {
  isEditMode: boolean;
  form: FormInstance<any>;
  options: TBonusTypesDrop[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const paymentBonuses = form.getFieldValue("paymentBonuses");
  useEffect(() => {
    if (paymentBonuses) {
      if (paymentBonuses?.length > 0) {
        setIsOpen(true);
      }
    }
  }, [paymentBonuses]);

  return (
    <div
      className={`w-full px-[14px] py-[8px] ${isOpen ? "h-fit !py-[12px]" : "h-[43.14px]"} border-[#D0D5DD] border-[1px] bg-white  overflow-hidden transition-all  flex flex-col `}
    >
      <div
        className="w-full flex items-center justify-between cursor-pointer "
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <p className="text-[16px] 2xl:text-[18px] font-[300] text-[#667085] ">
          Bonuses
        </p>
        <MdKeyboardArrowDown
          className={`text-[16px] text-[#667085] transition-all ${isOpen && "!rotate-[180deg]"} `}
        />
      </div>
      <Form.List name="paymentBonuses">
        {(fields, { add, remove }) => (
          <div className="clauseBox mt-[20px] flex flex-col gap-[20px]">
            {fields.map(({ key, name, ...restField }, index) => (
              <ClauseSingleBox
                isEditMode={isEditMode}
                name={name}
                keyI={key}
                form={form}
                index={index}
                deleteClause={remove}
                fields={fields}
                options={options}
                {...restField}
              />
            ))}
            {isEditMode && (
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  className={` ${fields.length > 0 && "!mt-[20px]"} mt-[37px]  Nunito w-full h-[40px] flex items-center justify-center bg-[#F3FAF7] shadow-none hover:!bg-[#F3FAF7] hover:opacity-[0.8] font-[400] !text-[#03543F] hover:!text-[#03543F] text-[14px] 2xl:text-[16px] rounded-[8px]  `}
                  loading={false}
                  onClick={() => add({})}
                >
                  + Add Bonus
                </Button>
              </Form.Item>
            )}
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default ClausesBox;
