import gql from "graphql-tag";

export const INITIATE_WITHDRAWAL = gql`
  mutation InitiateWithdrawalRequest(
    $withdrawalRequestInput: WithdrawalRequestInput!
  ) {
    data: initiateWithdrawalRequest(
      withdrawalRequestInput: $withdrawalRequestInput
    ) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_WITHDRAWALS = gql`
  query FetchWithdrawals($params: QueryParamsInput!) {
    data: fetchWithdrawals(params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_WITHDRAWAL = gql`
  query FetchWithdrawal($withdrawalId: String!) {
    data: fetchWithdrawal(withdrawalId: $withdrawalId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const REPORT_WITHDRAWAL = gql`
  mutation ReportWithdrawal($message: String!, $withdrawalId: String!) {
    data: reportWithdrawal(message: $message, withdrawalId: $withdrawalId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;
