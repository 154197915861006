import SettingsNavBarItem from "./SettingsNavBarItem";

const SettingsNavBar = () => {
  return (
    <nav className="w-full h-fit flex flex-col justify-between ">
      <div className="flex gap-[46px] max-md:gap-[20px] overflow-auto">
        <SettingsNavBarItem name="Profile" route="/settings/profile" />
        <SettingsNavBarItem name="Messages" route="/settings/messages" />
        <SettingsNavBarItem
          name="Notifications"
          route="/settings/notifications"
        />
        <SettingsNavBarItem name="Billing" route="/settings/billing" />
        <SettingsNavBarItem
          name="Accounts/beneficiary"
          route="/settings/account"
        />
      </div>
      <div className="w-full h-[1px] bg-[#EAECF0]"></div>
    </nav>
  );
};

export default SettingsNavBar;
