import { TBonusType } from "./payment-bonuses.types";

export type TStatusDropItem = {
  field?: string;
  value?: string | number;
};

export type TAccountDropItem = {
  name?: string;
  symbol?: string;
  icon?: string;
};

export type TSortDropItem = {
  field?: string;
  order?: SortOrder;
  key?: string;
};

export type TNotification = "success" | "info" | "warning" | "error";

// type TTableDataKeys = "key" | "payment_channel" | "amount" | "date" | "status";
export type TTableData = {
  _id: string;
  key: string;
  paymentChannel?: string;
  amount?: number | string;
  date?: string;
  avatar?: string;
  // status: "Completed" | "Pending" | "Failed";
  status?: string;
  select?: string;
  fullName?: string;
  email?: string;
  country?: string;
  devices?: string[];
  destinationAccount?: TAccount;
  paymentInstalments?: TInstallment[];
  device?: TDeviceData;
  customer?: TCustomerData;
  customerId?: string;
  createdAt: Date;
  updatedAt: Date;
};
export type TTableDataArr = TTableData[];

export type TTableColumn = {
  title: string;
  dataIndex: string;
  key: string;
};

export type TInstallment = {
  activationAmount: number | undefined;
  activationDays: number | undefined;
}[];

export type TClause = {
  activationAmount: number | undefined;
  activationDays: number | undefined;
  name: TClauseTypes | undefined;
  totalAmount: number | undefined;
};

export type TClauseTypes = "Custom total amount";
export type TFiatAccountCard = { name: string; bank: string; accNo: string };

export type TAddCustomer = {
  address: string;
  avatar: string;
  country: string;
  devices: string[];
  email: string;
  fullName: string;
  gender: string;
  phone: string;
  wallet: string;
};

export type TDashBoardChart = {
  monthly: { x: string[]; y: number[] };
  weekly: { x: string[]; y: number[] };
  yearly: { x: string[]; y: number[] };
};
export type TChartData = { x: string[]; y: number[] };

export type TCreatePayment = {
  planName: string;
  fullPaymentAmount: number;
  noOfDevices: number;
  paymentInstalments: TInstallment[];
  paymentBonuses: TBonusType[];
};
export type TAddDevice = {
  serialNumber: string;
  startingCode: string;
  key: string;
  count: number;
  paymentPlan: string;
  totalCost: number;
  deviceBrand: string;
  deviceModel: string;
};

export type TPlanDropItem = {
  _id: string;
  planName: string;
  fullPaymentAmount?: number;
  noOfDevices?: number;
  paymentInstalments?: TInstallment[];
  paymentBonuses?: TClause[];
};

export type TDeviceBrandDropItem = {
  _id: string;
  brandId: number;
  brandName: string;
  brandLogo: string;
  contactDetails?: {
    address: string;
    email: string;
    phoneNumber: string;
    website: string;
    _id: string;
  };
  country: string;
  createdAt: Date;
  paymentBonuses: Date;
};

export type TDeviceModelDropItem = {
  _id: string;
  avgPrice: string;
  batteryCapacity: string;
  brand: TDeviceBrandDropItem;
  description: string;
  deviceType: string;
  modelId: number;
  modelName: string;
  powerOutput: string;
  updatedAt: Date;
  createdAt: Date;
};

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum DeviceStatus {
  AVAILABLE = "available",
  PURCHASED = "purchased",
}

export enum TransactionStatus {
  SUCCESSFUL = "successful",
  REFUNDED = "refunded",
}

export enum WithdrawalStatus {
  SUCCESSFUL = "successful",
  PENDING = "pending",
  REFUNDED = "refunded",
}

export type TParams = {
  sort?: { field?: string; order?: SortOrder };
  filter?: { field?: string; value?: string | number };
  pagination?: { skip?: number; take?: number; page?: number };
  search?: { key?: string };
};

export type TPageParamInput = { pageNo: number; pageLimit: number };
export type TQueryParams = {
  queryKey: (
    | string
    | TPageParamInput
    | TStatusDropItem
    | TSortDropItem
    | undefined
  )[];
  signal: AbortSignal;
  meta: Record<string, unknown> | undefined;
  pageParam?: unknown;
  direction?: unknown;
};

export type TDeviceData = {
  _id: string;
  amountPaid: number;
  count: number;
  createdAt: Date;
  isActive: boolean;
  key: string;
  owner: string;
  paygMode: string;
  paymentPlan: TPlanDropItem;
  serialNumber: string;
  startingCode: string;
  status: string;
  totalCost: number;
  updatedAt: Date;
  __v: number;
};

export interface TDeviceUploadData {
  hardwaremodel: string;
  key: string;
  point: string;
  restricteddigitmode: string;
  serialnumber: string;
  startingcode: string;
  testcode: string;
  timedivider: string;
  count: string | number;
  version: string;
  paymentPlan?: string;
}

export type TCustomerData = {
  _id: string;
  address: string;
  avatar: string;
  country: string;
  createdAt: Date;
  customerId: string;
  devices: TDeviceData[];
  distributor: string;
  email: string;
  fullName: string;
  phone: string;
  referralCode: string;
  updatedAt: Date;
  wallet: string;
  __v: number;
};

export type TTransactionData = {
  _id: string;
  account: string;
  amount: number;
  createdAt: Date;
  customer: TCustomerData;
  description: string;
  device: TDeviceData;
  distributor: string;
  flag: boolean;
  memo: string;
  netPay: number;
  status: string;
  transactionFee: number;
  transactionHash: null;
  transactionId: string;
  updatedAt: Date;
  __v: number;
};

export type TPaymentData = {
  createdAt: Date;
  distributor: string;
  fullPaymentAmount: number;
  noOfDevices: number;
  paymentBonuses: TBonusType[];
  paymentInstalments: TInstallment[];
  planName: string;
  updatedAt: Date;
  __v: number;
  _id: string;
};

export type TUpdateUser = {
  avatar?: string;
  fullName?: string;
  paymentId?: string;
  phone?: string;
  country?: string;
};
export type TUpdateUserPassword = {
  currentPassword: string;
  newPassword: string;
};

export type TCurrency = {
  _id: string;
  name: string;
  code: string;
  country: string;
  flag: string;
  rate: number;
  symbol: string;
};

export type TAddFiatAccount = {
  country: string;
  bank: string;
  accName: string;
  accNumber: string;
  bankCode: string;
  currencyId: string;
};

export type TFAccountCurrencySub = {
  flag: string;
  currency: string;
  code: string;
  country: string;
  symbol: string;
};

export type TFiatAccountSub = {
  distributor: string;
  accName: string;
  accNumber: string;
  bank: string;
  currency: TFAccountCurrencySub;
};

export type TFiatAccount = {
  distributor: string;
  accountType: string;
  currencyCode: string;
  accountData: TFiatAccountSub;
  status: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  _id: string;
};

export enum UsdcChains {
  SOLANA = "solana",
  STELLAR = "stellar",
}

export enum UsdcChainsImg {
  SOLANA = "https://res.cloudinary.com/dx4trf7im/image/upload/v1723126174/logos/solana-sol-logo_oan2p4.svg",
  STELLAR = "https://res.cloudinary.com/dx4trf7im/image/upload/v1723126209/logos/stellar-xlm-logo_lawu50.svg",
}

export type TUsdcAccountCard = {
  chain: string;
  publicKey: string;
};

export type TAddUsdcAccount = {
  publicKey: string;
  chain: UsdcChains;
};

export type TUsdcAccountSub = {
  distributor: string;
  publicKey: string;
  chain: UsdcChains;
  currency: TFAccountCurrencySub;
};

export type TUsdcAccount = {
  accountData: TUsdcAccountSub;
  accountType: string;
  currencyCode: TFAccountCurrencySub;
  distributor: string;
  status: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  _id: string;
};

export type TAccount = {
  distributor: string;
  accountType: string;
  currencyCode: string;
  accountData: TFiatAccountSub | TUsdcAccountSub;
  status: string;
  active: boolean;
};

export type TAxisData = {
  xAxis: string[];
  yAxis: number[];
};

export type TGraphData = {
  currency: TCurrency;
  data: { daily: TAxisData; monthly: TAxisData; yearly: TAxisData };
};

export type TGraphTimeline = "daily" | "monthly" | "yearly";
export type TChartTimeline = "weekly" | "monthly" | "yearly";

export type TRevenueData = {
  totalRevenue: number;
  currencySymbol: string;
  lastUpdated: Date;
  currencyIcon: string;
  percentageChange: number;
  currency: TCurrency;
};

export type TAccBalanceData = {
  availableBalance: number;
  currencySymbol: string;
  lastUpdated: Date;
  currencyIcon: string;
  // account?: TAccount;
  account?: TUsdcAccount | TFiatAccount;
};

export type TRevenueOverview = {
  successCount: number;
  refundCount: number;
  successRate: number;
  refundRate: number;
  baseRate: number;
};

export type TWithdrawalInput = {
  account: string;
  type: "fiat" | "usdc";
  amount: number;
};
