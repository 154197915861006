import { toast } from "sonner";
import Papa from "papaparse"; // PapaParse library for CSV parsing
import { TAddDevice, TDeviceData, TDeviceUploadData } from "../types/types";
import { SetStateAction } from "react";

const headersToCheckFor = ["Serial Number", "Starting Code", "Key", "Count"];

const revampHeader = (str: string) => {
  const normalizedString = str.toLowerCase().replace(/[^a-z0-9]/g, "");
  return normalizedString;
};

export const parseCSV = (
  file: File,
  setAllDevicesData: React.Dispatch<SetStateAction<TAddDevice[]>>,
  paymentPlanForAll: string | undefined,
  paymentPlanCostForAll: number | undefined
) => {
  Papa.parse(file, {
    header: true, // Set to true to parse headers
    complete: (results: any) => {
      const headers: string[] = results.meta.fields;
      const revampedHeaders = headers.map((datum) => {
        return revampHeader(datum);
      });

      //check if all headers are complete
      const headerChecker = headersToCheckFor.every((datum) => {
        if (revampedHeaders.includes(revampHeader(datum))) {
          return true; // Continue iteration
        }
        return false; // Stop iteration
      });

      if (!headerChecker) {
        toast.error("Some Required headers in the CSV File are missing");
        return;
      }

      // Map the CSV data to extract columns
      const data = results.data;

      const extractedDevicesData: TAddDevice[] = data.map((row: any) => {
        return {
          serialNumber: row[headers[revampedHeaders.indexOf("serialnumber")]],
          startingCode: row[headers[revampedHeaders.indexOf("startingcode")]],
          key: row[headers[revampedHeaders.indexOf("key")]],
          count: Number(row[headers[revampedHeaders.indexOf("count")]]),
          paymentPlan: paymentPlanForAll ? paymentPlanForAll : "",
          totalCost: paymentPlanCostForAll ? paymentPlanCostForAll : 0,
        };
      });

      //filter the extracted data and check for empty data
      const checkIfAnyDataIsEmpty: TAddDevice[] = extractedDevicesData.filter(
        (datum: TAddDevice) => {
          return datum.serialNumber && datum.startingCode && datum.key;
        }
      );

      setAllDevicesData(checkIfAnyDataIsEmpty);
    },
    error: (error: any) => {
      toast.error("Error loading CSV");
    },
  });
};
