import { FiInbox } from "react-icons/fi";
import { TCustomerData, TTransactionData } from "../../types/types";
import CustomerTransactionBox from "./CustomerTransactionBox";
import { initCustomerTransactionData } from "../../utils/constants";

const mock = [1, 2, 3, 4, 5];
const CustomerTransactionsSection = ({
  customerData,
  data,
  isLoading,
}: {
  customerData: TCustomerData;
  data: TTransactionData[];
  isLoading: boolean;
}) => {
  return (
    <div className="customerTransactions w-full flex flex-col gap-[14px]">
      {isLoading ? (
        mock.map((datum, index) => {
          return (
            <CustomerTransactionBox
              key={index}
              isLoading={true}
              data={initCustomerTransactionData}
              customerData={customerData}
            />
          );
        })
      ) : data.length > 0 ? (
        data.map((datum: TTransactionData, index: number) => {
          return (
            <CustomerTransactionBox
              key={index}
              isLoading={isLoading}
              data={datum}
              customerData={customerData}
            />
          );
        })
      ) : (
        <div className="w-full max-lg:h-[300px] lg:h-[200px] flex items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-2">
            <FiInbox className="text-[#dddddd] text-[35px] " />
            <p className="Nunito text-[#dddddd] font-[400] text-[14px] ">
              No Transactions
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerTransactionsSection;
