import { RxCross2 } from "react-icons/rx";
import {
  TAddDevice,
  TDeviceModelDropItem,
  TPlanDropItem,
} from "../../types/types";
import PlanDropDown from "./PlanDropDown";
import { DeviceIconSvg } from "./svg";
import { SetStateAction, useEffect, useState } from "react";

const DeviceFromCsv = ({
  sn,
  Serial_No,
  Device_key,
  generalModel,
  generalPlan,
  allPaymentPlans,
  allModel,
  allDevicesData,
  setAllDevicesData,
}: {
  sn: number;
  Serial_No: string;
  Device_key: string;
  generalPlan?: string;
  generalModel?: string;
  allPaymentPlans: TPlanDropItem[];
  allModel: TDeviceModelDropItem[];
  allDevicesData: TAddDevice[];
  setAllDevicesData: React.Dispatch<SetStateAction<TAddDevice[]>>;
}) => {
  const [devicePlan, setDevicePlan] = useState(generalPlan);
  const [deviceModel, setDeviceModel] = useState(generalModel);

  const handlePaymentPlanChange = (value: string) => {
    //get plan cost
    const currentPlan = allPaymentPlans?.filter((datum: TPlanDropItem) => {
      return datum._id === value;
    });

    //update this device data
    setDevicePlan(value);
    const updatedDevices = allDevicesData.map((datum) => {
      if (datum.key === Device_key) {
        return {
          ...datum,
          paymentPlan: value,
          totalCost: currentPlan[0].fullPaymentAmount || 0,
        };
      }
      return datum;
    });
    setAllDevicesData(updatedDevices);
  };

  const handleModelChange = (value: string) => {
    //update this device data
    setDeviceModel(value);
    const updatedDevices = allDevicesData.map((datum) => {
      if (datum.key === Device_key) {
        return {
          ...datum,
          deviceModel: value,
        };
      }
      return datum;
    });
    setAllDevicesData(updatedDevices);
  };

  const deleteDeviceFromCsvData = () => {
    const filteredDevices = allDevicesData.filter((datum) => {
      return datum.serialNumber !== Serial_No;
    });
    setAllDevicesData(filteredDevices);
  };

  useEffect(() => {
    setDevicePlan(generalPlan);
    setDeviceModel(generalModel);
  }, [generalPlan, generalModel]);

  return (
    <div className="flex items-center justify-between px-4 py-2 max-sm:py-4 border-[1px] border-[#D1D5DB] rounded-[8px] gap-4 max-md:flex-col ">
      <div className="flex items-center gap-4 max-md:w-full">
        <div className="flex items-center gap-[2px]">
          <p className="text-[14px] 2xl:text-[16px] font-[600] text-[#6B7280] ">
            {sn + 1}
          </p>
          <DeviceIconSvg className="" />
        </div>
        <p className="text-[14px] 2xl:text-[16px] font-[600] text-[#6B7280] ">
          {Serial_No}
        </p>

        <div className="flex-1 flex justify-end items-center max-md:flex hidden">
          <RxCross2
            className="cursor-pointer text-[18px] "
            onClick={deleteDeviceFromCsvData}
          />
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2 flex-wrap">
          <div className="max-w-full w-[160px] max-sm:w-[200px]">
            <PlanDropDown
              placeholder="Device model"
              options={allModel || []}
              state={deviceModel}
              setState={handleModelChange}
              setPlanCost={() => {}}
            />
          </div>
          <div className="max-w-full w-[160px] max-sm:w-[200px]">
            <PlanDropDown
              placeholder="Payment Plan"
              options={allPaymentPlans || []}
              state={devicePlan}
              setState={handlePaymentPlanChange}
              setPlanCost={() => {}}
              readOnly={false}
            />
          </div>
        </div>
        <RxCross2
          className="cursor-pointer text-[18px] max-md:hidden"
          onClick={deleteDeviceFromCsvData}
        />
      </div>
    </div>
  );
};

export default DeviceFromCsv;
