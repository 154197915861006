import {
  DeviceStatus,
  SortOrder,
  TransactionStatus,
  WithdrawalStatus,
} from "../types/types";

export const initPaginationData = {
  currentPage: 1,
  pageItems: 1,
  totalItems: 1,
  totalPages: 1,
  totalFilteredItems: 1,
};
//Dashboard page
//Dashboard page
//Dashboard page
export const dashboardColumns = [
  {
    title: "WITHDRAWAL ID",
    dataIndex: "withdrawalId",
    key: "withdrawalId",
  },
  {
    title: "PAYMENT TYPE",
    dataIndex: "payment_type",
    key: "payment_type",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "CURRENCY",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
];

export const dashboardTableData = [
  {
    _id: "1",
    key: "1",
    payment_channel: "Bank Account",
    amount: 100,
    date: new Date().toLocaleDateString(),
    status: "failed",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  {
    _id: "2",
    key: "2",
    payment_channel: "USDC wallet",
    amount: 100,
    date: new Date().toLocaleDateString(),
    status: "completed",
    createdAt: new Date(),
    updatedAt: new Date(),
  },
];

export const dashboardStatusOptions = [
  { field: "", value: "All" },
  { field: "status", value: WithdrawalStatus.SUCCESSFUL },
  { field: "status", value: WithdrawalStatus.PENDING },
  { field: "status", value: WithdrawalStatus.REFUNDED },
];

export const dashboardSortOptions = [
  { order: SortOrder.DESC, field: "createdAt", key: "1" },
  { order: SortOrder.ASC, field: "createdAt", key: "0" },
];

//device page
//device page
//device page
export const deviceTableColumns = [
  {
    title: "",
    dataIndex: "select",
    key: "select",
  },
  {
    title: "SERIAL NUMBER",
    dataIndex: "serialNumber",
    key: "serialNumber",
  },
  // {
  //   title: "STARTING CODE",
  //   dataIndex: "startingCode",
  //   key: "startingCode",
  // },

  {
    title: "PAYMENT PLAN",
    dataIndex: "paymentPlan",
    key: "paymentPlan",
  },
  {
    title: "AMOUNT PAID",
    key: "amountPaid",
    dataIndex: "amountPaid",
  },
  {
    title: "TOTAL COST",
    key: "totalCost",
    dataIndex: "totalCost",
  },
  {
    title: "PAY GO MODE",
    dataIndex: "paygMode",
    key: "paygMode",
  },
  {
    title: "ACTIVE",
    dataIndex: "isActive",
    key: "isActive",
  },

  // {
  //   title: "COUNT",
  //   dataIndex: "count",
  //   key: "count",
  // },

  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
];

export const deviceTableData = [
  {
    _id: "1",
    key: "1",
    select: "",
    serial_number: "34534543435",
    starting_code: "62432445233",
    count: "3",
    amount: "200",
  },
  {
    _id: "2",
    key: "2",
    select: "",
    serial_number: "34534543435",
    starting_code: "62432445233",
    count: "3",
    amount: "200",
  },
  {
    _id: "3",
    key: "3",
    select: "",
    serial_number: "34534543435",
    starting_code: "62432445233",
    count: "3",
    amount: "200",
  },
];

export const deviceStatusOptions = [
  { field: "", value: "All" },
  { field: "status", value: DeviceStatus.PURCHASED },
  { field: "status", value: DeviceStatus.AVAILABLE },
];

export const deviceSortOptions = [
  { order: SortOrder.DESC, field: "createdAt", key: "1" },
  { order: SortOrder.ASC, field: "createdAt", key: "0" },
];

export const initialDeviceData = {
  _id: "",
  amountPaid: 0,
  count: 0,
  createdAt: new Date(),
  isActive: false,
  key: "",
  owner: "",
  paygMode: "",
  paymentPlan: {
    _id: "",
    planName: "",
    fullPaymentAmount: 0,
    noOfDevices: 0,
    paymentBonuses: [],
    paymentClauses: [],
  },
  serialNumber: "",
  startingCode: "",
  status: "",
  totalCost: 0,
  updatedAt: new Date(),
  __v: 0,
};

//customer page
//customer page
//customer page
export const customerTableColumns = [
  {
    title: "",
    dataIndex: "select",
    key: "select",
  },
  {
    title: "FULL NAME",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "COUNTRY",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "NO OF DEVICES",
    key: "devices",
    dataIndex: "devices",
  },
];

export const customerTableData = [
  {
    key: "1",
    select: "",
    fullName: "Jane Karmel",
    email: "olivia@paygo.com",
    country: "Nigeria",
    devices: "2",
  },
  {
    key: "2",
    select: "",
    fullName: "Jane Karmel",
    email: "olivia@paygo.com",
    country: "Nigeria",
    devices: "2",
  },
  {
    key: "3",
    select: "",
    fullName: "Jane Karmel",
    email: "olivia@paygo.com",
    country: "Nigeria",
    devices: "13",
  },
];

export const costumerStatusOptions = [
  { field: "", value: "All" },
  { field: "status", value: "purchased" },
  { field: "status", value: "available" },
];

export const customerSortOptions = [
  { order: SortOrder.DESC, field: "createdAt", key: "1" },
  { order: SortOrder.ASC, field: "createdAt", key: "0" },
];

export const initCustomerData = {
  _id: "",
  address: "",
  avatar: "",
  country: "",
  createdAt: new Date(),
  customerId: "",
  devices: [],
  distributor: "",
  email: "",
  fullName: "",
  phone: "",
  referralCode: "",
  updatedAt: new Date(),
  wallet: "",
  __v: 0,
};

//transactions page
//transactions page
//transactions page
export const transactionColumns = [
  {
    title: "FULL NAME",
    dataIndex: "customerFullName",
    key: "customerFullName",
  },
  {
    title: "DEVICE",
    dataIndex: "device",
    key: "device",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "CURRENCY",
    dataIndex: "currencyCode",
    key: "currencyCode",
  },
  {
    title: "DATE",
    key: "date",
    dataIndex: "date",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
];

export const transactionData = [
  {
    _id: "1",
    key: "1",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Completed",
  },
  {
    _id: "2",
    key: "2",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Completed",
  },
  {
    _id: "3",
    key: "3",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Refunded",
  },
  {
    _id: "4",
    key: "4",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Completed",
  },
  {
    _id: "5",
    key: "5",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Refunded",
  },
  {
    _id: "6",
    key: "6",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Completed",
  },
  {
    _id: "7",
    key: "7",
    customerFullName: "Jane Karmel",
    device: "olivia@paygo.com",
    amount: "100 USDC",
    date: "9/04/024",
    status: "Completed",
  },
];

export const transactionStatusOptions = [
  { field: "", value: "All" },
  { field: "status", value: TransactionStatus.SUCCESSFUL },
  { field: "status", value: TransactionStatus.REFUNDED },
];

export const transactionSortOptions = [
  { order: SortOrder.DESC, field: "createdAt", key: "1" },
  { order: SortOrder.ASC, field: "createdAt", key: "0" },
];

export const initCustomerTransactionData = {
  _id: "",
  account: "",
  amount: 0,
  createdAt: new Date(),
  customer: initCustomerData,
  description: "",
  device: initialDeviceData,
  distributor: "",
  flag: false,
  memo: "",
  netPay: 0,
  status: "",
  transactionFee: 0,
  transactionHash: null,
  transactionId: "",
  updatedAt: new Date(),
  __v: 0,
};

//transactions page
//transactions page
//transactions page
export const paymentPlansColumns = [
  {
    title: "",
    dataIndex: "select",
    key: "select",
  },
  {
    title: "NAME",
    dataIndex: "planName",
    key: "planName",
  },
  {
    title: "TOTAL",
    dataIndex: "fullPaymentAmount",
    key: "fullPaymentAmount",
  },
  {
    title: "NO OF INSTALLMENTS",
    dataIndex: "installments",
    key: "installments",
  },
  {
    title: "NO OF DEVICES",
    key: "noOfDevices",
    dataIndex: "noOfDevices",
  },
];

export const paymentPlansTableData = [
  {
    _id: "1",
    key: "1",
    select: "",
    fullName: "Jane Karmel",
    total: "200",
    country: "Nigeria",
    devices: "2",
  },
  {
    _id: "2",
    key: "2",
    select: "",
    fullName: "Jane Karmel",
    total: "160",
    country: "Nigeria",
    devices: "2",
  },
  {
    _id: "3",
    key: "3",
    select: "",
    fullName: "Jane Karmel",
    total: "500",
    country: "Nigeria",
    devices: "13",
  },
];

export const paymentPlansStatusOptions = [
  { field: "", value: "All" },
  { field: "status", value: "purchased" },
  { field: "status", value: "available" },
];

export const paymentPlansSortOptions = [
  { order: SortOrder.DESC, field: "createdAt", key: "1" },
  { order: SortOrder.ASC, field: "createdAt", key: "0" },
];

//user Data
//user Data
//user Data

export const initUserData = {
  avatar: "",
  accountPlan: "",
  accounts: [],
  createdAt: new Date(),
  deleted: false,
  email: "",
  fullName: "",
  password: "",
  paymentId: "",
  phone: "",
  role: "",
  country: "",
  updatedAt: new Date(),
  __v: 0,
  _id: "",
};

export const graphTimelineOptions = [
  { field: "daily", value: "daily" },
  { field: "monthly", value: "monthly" },
  { field: "yearly", value: "yearly" },
];

export const chartTimelineOptions = [
  { field: "This Week", value: "weekly" },
  { field: "This Month", value: "monthly" },
  { field: "This Year", value: "yearly" },
];
