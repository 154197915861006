import gql from "graphql-tag";

export const GET_DISTRIBUTOR = gql`
  query GetDistributorData {
    data: getDistributorData {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const DELETE_DISTRIBUTOR = gql`
  query DeleteDistributor {
    data: deleteDistributor {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const UPDATE_DISTRIBUTOR = gql`
  mutation UpdateDistributor($updateDistributorInput: UpdateDistributorInput!) {
    data: updateDistributor(updateDistributorInput: $updateDistributorInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const SEND_UPDATE_DISTRIBUTOR_EMAIL_MAIL = gql`
  mutation UpdateEmailRequest {
    data: updateEmailRequest {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const SEND_UPDATE_DISTRIBUTOR_PASSWORD_MAIL = gql`
  mutation UpdateDistributorPassword(
    $currentPassword: String!
    $newPassword: String!
  ) {
    data: updateDistributorPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const GET_DISTRIBUTOR_FIAT_ACCOUNTS = gql`
  query FetchFiatAccounts {
    data: fetchFiatAccounts {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const GET_DISTRIBUTOR_USDC_ACCOUNTS = gql`
  query FetchUsdcAccounts {
    data: fetchUsdcAccounts {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const ADD_DISTRIBUTOR_FIAT_ACCOUNT = gql`
  mutation CreateFiatAccount($createFiatAccountInput: CreateFiatAccountInput!) {
    data: createFiatAccount(createFiatAccountInput: $createFiatAccountInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const ADD_DISTRIBUTOR_USDC_ACCOUNT = gql`
  mutation CreateUsdcAccount($createUsdcAccountInput: CreateUsdcAccountInput!) {
    data: createUsdcAccount(createUsdcAccountInput: $createUsdcAccountInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const SET_DISTRIBUTOR_ACTIVE_FIAT_ACCOUNT = gql`
  mutation SetActiveFiatAccount($fiatAccountId: String!) {
    data: setActiveFiatAccount(fiatAccountId: $fiatAccountId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const SET_DISTRIBUTOR_ACTIVE_USDC_ACCOUNT = gql`
  mutation SetActiveUsdcAccount($usdcAccountId: String!) {
    data: setActiveUsdcAccount(usdcAccountId: $usdcAccountId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const DELETE_DISTRIBUTOR_FIAT_ACCOUNT = gql`
  mutation DeleteFiatAccount($fiatAccountId: String!) {
    data: deleteFiatAccount(fiatAccountId: $fiatAccountId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const DELETE_DISTRIBUTOR_USDC_ACCOUNT = gql`
  mutation DeleteUsdcAccount($usdcAccountId: String!) {
    data: deleteUsdcAccount(usdcAccountId: $usdcAccountId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;
