import { TCustomerData, TTransactionData } from "../../types/types";
import DrawerTextStack from "../atoms/DrawerTextStack";
import StatusValue from "../atoms/StatusValue";

const CustomerTransactionBox = ({
  isLoading,
  data,
  customerData,
}: {
  isLoading: boolean;
  data: TTransactionData;
  customerData: TCustomerData;
}) => {
  return (
    <div className="flex pb-[23px] border-b-[1px] border-b-[#E5E7EB] items-center justify-between ">
      <div className="flex items-center gap-[14px]  ">
        <div className="bg-[#D9D9D9] rounded-full h-[46px] w-[46px] "></div>
        <DrawerTextStack
          placement={"left"}
          isLoading={isLoading}
          heading={data.transactionHash || ""}
          value={new Date(data?.createdAt || "").toUTCString() || ""}
        />
      </div>
      <div className="flex flex-col gap-[5px] items-end">
        <p className="text-black text-[14px] 2xl:text-[16px] font-[500] ">
          {data.amount || 0} USDC
        </p>
        <StatusValue value={data.status} />
      </div>
    </div>
  );
};

export default CustomerTransactionBox;
