import { ReactElement } from "react";
import SettingsGreeting from "../../components/atoms/SettingsGreeting";
import SettingsNavBar from "../../components/layout/NavigationBar/SettingsNavBar";

export function Settings(): ReactElement {
  return (
    <div className="w-full">
      <SettingsGreeting />
      <div className="">
        <SettingsNavBar />
      </div>
    </div>
  );
}

export default Settings;
