export enum ProtectedRoutes {
  BASE = "/",
  DASHBOARD = "/",
  CUSTOMERS = "/customers",
  PAYMENT_PLANS = "/payment-plans",
  TRANSACTIONS = "/transactions",
  DEVICES = "/devices",
  SETTINGS = "/settings",
  PROFILE = "/settings/profile",
  MESSAGES = "/settings/messages",
  NOTIFICATIONS = "/settings/notifications",
  BILLING = "/settings/billing",
  ACCOUNT = "/settings/account",
  CHANGE_EMAIL = "/update-email/:token",
  NOT_FOUND = "*",
}

export enum PublicRoutes {
  AUTH = "/auth",
  SIGNIN = "/auth",
  REGISTER = "/auth/register",
  VERIFY_EMAIL = "/auth/verify-email",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password/:token",
}
