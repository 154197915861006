import { capitalizeWords } from "../../utils/helper";

const AccountRadio = ({
  value,
  activeFundType,
  changeActiveFundType,
}: {
  value: "fiat" | "usdc";
  activeFundType: "fiat" | "usdc";
  changeActiveFundType: (value: "fiat" | "usdc") => void;
}) => {
  return (
    <div
      className="flex items-center gap-[5px] cursor-pointer "
      onClick={() => {
        changeActiveFundType(value);
      }}
    >
      <div
        className={`w-[15px] h-[15px] rounded-full border-[0.75px] border-[#D0D5DD] ${activeFundType === value && "border-darkYellow"} transition-all flex items-center justify-center `}
      >
        {activeFundType === value && (
          <div className="bg-darkYellow rounded-full w-[6px] h-[6px] transition-all "></div>
        )}
      </div>
      <p
        className={`text-[14px] ${activeFundType === value ? "font-[400] text-darkYellow" : "font-[300] text-[#667085]"}`}
      >
        {capitalizeWords(value)}
      </p>
    </div>
  );
};

export default AccountRadio;
