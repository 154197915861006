import { DefaultResponse } from "../../types/default-response.dto";
import { TParams } from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  FETCH_TRANSACTION,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTION_STATS,
  REPORT_TRANSACTION,
} from "./transactions.queries";

export const getTransactions = async (
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_TRANSACTIONS, {
    params: { ...params },
  });
};

export const getTransaction = async (
  transactionId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_TRANSACTION, {
    transactionId,
  });
};

export const reportTransaction = async (
  transactionId: string,
  message: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(REPORT_TRANSACTION, {
    transactionId,
    message,
  });
};

export const getTransactionsStats = async (
  lastXDays: number
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_TRANSACTION_STATS, {
    lastXDays,
  });
};
