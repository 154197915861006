import { DefaultResponse } from "../../types/default-response.dto";
import { TAddDevice, TParams } from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  ADD_DEVICE,
  ADD_DEVICES,
  DELETE_DEVICES,
  FETCH_ALL_DEVICE_MODELS,
  FETCH_DEVICE,
  FETCH_DEVICES,
  FETCH_DEVICE_BRANDS,
  FETCH_DEVICE_COUNT,
  FETCH_DEVICE_MODEL_BY_BRAND,
  FETCH_SOLD_OUT_DEVICE_COUNT,
  LINK_DEVICE,
  UNLINK_DEVICE,
  UPDATE_DEVICE,
} from "./devices.queries";

export const getDevices = async (
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_DEVICES, {
    params: { ...params },
  });
};

export const getDevice = async (deviceId: string): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_DEVICE, {
    deviceId,
  });
};

export const addDevice = async (data: TAddDevice): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(ADD_DEVICE, {
    addDeviceInput: {
      serialNumber: data.serialNumber,
      startingCode: data.startingCode,
      key: data.key,
      count: data.count,
      paymentPlan: data.paymentPlan,
      totalCost: data.totalCost,
      // deviceBrand: data.deviceBrand,
      deviceModel: data.deviceModel,
    },
  });
};

export const addDevices = async (
  addDevicesInput: TAddDevice[]
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(ADD_DEVICES, {
    addDevicesInput,
  });
};

export const deleteDevices = async (
  deviceIds: string[]
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(DELETE_DEVICES, {
    deviceIds,
  });
};

export const updateDevice = async (
  deviceId: string,
  data: TAddDevice
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(UPDATE_DEVICE, {
    deviceId,
    editDeviceInput: {
      serialNumber: data.serialNumber,
      startingCode: data.startingCode,
      key: data.key,
      count: data.count,
      paymentPlan: data.paymentPlan,
      totalCost: data.totalCost,
      deviceModel: data.deviceModel,
    },
  });
};

export const linkDevice = async (
  customerId: string,
  deviceId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(LINK_DEVICE, {
    customerId,
    deviceId,
  });
};

export const unLinkDevice = async (
  customerId: string,
  deviceId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(UNLINK_DEVICE, {
    customerId,
    deviceId,
  });
};

export const getDevicesCount = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_DEVICE_COUNT, {});
};

export const getSoldOutDevicesCount = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_SOLD_OUT_DEVICE_COUNT, {});
};

export const getDeviceBrands = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_DEVICE_BRANDS, {});
};

export const getDeviceModelsByBrands = async (
  brandId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_DEVICE_MODEL_BY_BRAND, {
    brandId,
  });
};

export const getAllDeviceModels = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_ALL_DEVICE_MODELS, {});
};
