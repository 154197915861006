export const cloudinaryUpload = async (formData: FormData | undefined) => {
  const response = await fetch(process.env.REACT_APP_CLOUDINARY_UPLOAD_API!, {
    method: "POST",
    body: formData,
  });
  return response;
};

const paystackBankApiBaseUrl = "https://api.paystack.co/bank";

export const getBankByCountry = async (country: string) => {
  const response = await fetch(`${paystackBankApiBaseUrl}?country=${country}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_TEST_SECRET_API_KEY}`,
    },
  });
  return response;
};

export const validateAccountNumber = async (
  accountNo: string,
  bankCode: string
) => {
  const response = await fetch(
    `${paystackBankApiBaseUrl}/resolve?account_number=${accountNo}&bank_code=${bankCode}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_TEST_SECRET_API_KEY}`,
      },
    }
  );
  return response;
};
