import { useEffect, useRef, useState } from "react";

let currentOtpIndex: number = 0;
const OTPInput = ({
  otp,
  setOtp,
}: {
  otp: string[];
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newOtp: string[] = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) {
      setActiveOtpIndex(currentOtpIndex - 1);
    } else {
      setActiveOtpIndex(currentOtpIndex + 1);
    }
    setOtp(newOtp);
  };

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOtpIndex = index;
    if (e.key === "Backspace") {
      setActiveOtpIndex(index - 1);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);
  return (
    <div className="w-full flex items-center justify-between gap-[11px] ">
      {otp.map((value, index) => (
        <input
          min={0}
          key={index}
          ref={index === activeOtpIndex ? inputRef : null}
          className="otpinput Nunito appearance-none h-[43px] w-full rounded-[10px] border-[1.5px] border-[#D1D5DB] focus:border-textGrey focus:outline-none flex items-center justify-center text-center bg-[#F9FAFB] text-[#6B7280] text-[16px] text-[20px] font-[300] "
          type="number"
          onChange={(e) => handleOnChange(e)}
          onKeyDown={(e) => handleOnKeyDown(e, index)}
          value={otp[index]}
        />
      ))}
    </div>
  );
};

export default OTPInput;
