import { useMutation } from "@tanstack/react-query";
import { ReactElement } from "react";
import { MutationKeys } from "../../enums/react-query";
import { LogoSvgFull } from "../../assets/icons/LogoSvgFull";
import { Button, Form, Input } from "antd";
import { changeEmail } from "../../services/auth/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import { PublicRoutes } from "../../types/Routes";

export function ChangeEmail(): ReactElement {
  const { token } = useParams();
  const navigate = useNavigate();
  const { mutate: changeEmailMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.CHANGEEMAIL],
    mutationFn: (email: string) => changeEmail(email, token || ""),
    onSuccess: (data) => {
      navigate(PublicRoutes.SIGNIN);
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const onFinish = (values: { email: string }) => {
    changeEmailMutate(values.email);
  };

  return (
    <div className="w-full h-full bg-[#FFFCF7] flex items-center justify-center">
      <div className="flex flex-col items-center gap-[27px] ">
        <LogoSvgFull w="150" h="150" />
        <div className="!w-[353px] h-fit p-[20px] rounded-[8px] bg-white border-[1px] border-[#E5E7EB] flex flex-col w-full ">
          <h6 className="Lato text-[#515151] font-[500] tracking-[1.25%] text-[20px] 2xl:text-[24px] ">
            Change Email
          </h6>
          <p className="mt-[6px] text-[#6B7280] font-[300] tracking-[1.25%] text-[14px] 2xl:text-[16px]">
            please input your new email!
          </p>
          <Form
            name="basic"
            style={{
              maxWidth: 600,
            }}
            initialValues={{}}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className=""
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "please input a valid email",
                },
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                className="mt-[10px] Nunito h-[42px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] font-[300] !text-[#9CA3AF] "
                placeholder="Email"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="mt-[24px] Nunito w-full h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow hover:opacity-[0.8] font-[500] text-[16px] 2xl:text-[18px] rounded-[8px]  "
                loading={isPending}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
