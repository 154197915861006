import { DefaultResponse } from "../../types/default-response.dto";
import {
  TAddFiatAccount,
  TAddUsdcAccount,
  TUpdateUser,
} from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  ADD_DISTRIBUTOR_FIAT_ACCOUNT,
  ADD_DISTRIBUTOR_USDC_ACCOUNT,
  DELETE_DISTRIBUTOR,
  DELETE_DISTRIBUTOR_FIAT_ACCOUNT,
  DELETE_DISTRIBUTOR_USDC_ACCOUNT,
  GET_DISTRIBUTOR,
  GET_DISTRIBUTOR_FIAT_ACCOUNTS,
  GET_DISTRIBUTOR_USDC_ACCOUNTS,
  SEND_UPDATE_DISTRIBUTOR_EMAIL_MAIL,
  SEND_UPDATE_DISTRIBUTOR_PASSWORD_MAIL,
  SET_DISTRIBUTOR_ACTIVE_FIAT_ACCOUNT,
  SET_DISTRIBUTOR_ACTIVE_USDC_ACCOUNT,
  UPDATE_DISTRIBUTOR,
} from "./distributor.queries";

export const getDistributor = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(GET_DISTRIBUTOR, {});
};

export const updateDistributor = async (
  data: TUpdateUser
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(UPDATE_DISTRIBUTOR, {
    updateDistributorInput: {
      ...data,
    },
  });
};

export const sendUpdateDistributorEmailMail =
  async (): Promise<DefaultResponse> => {
    const authClient = createAuthClient();
    return authClient.request(SEND_UPDATE_DISTRIBUTOR_EMAIL_MAIL, {});
  };

export const sendUpdateDistributorPasswordMail = async (
  currentPassword: string,
  newPassword: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(SEND_UPDATE_DISTRIBUTOR_PASSWORD_MAIL, {
    currentPassword,
    newPassword,
  });
};

export const deleteDistributor = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(DELETE_DISTRIBUTOR, {});
};

export const getDistributorFiatAccounts =
  async (): Promise<DefaultResponse> => {
    const authClient = createAuthClient();
    return authClient.request(GET_DISTRIBUTOR_FIAT_ACCOUNTS, {});
  };

export const getDistributorUsdcAccounts =
  async (): Promise<DefaultResponse> => {
    const authClient = createAuthClient();
    return authClient.request(GET_DISTRIBUTOR_USDC_ACCOUNTS, {});
  };

export const addFiatAccountForDistributor = async (
  data: TAddFiatAccount
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(ADD_DISTRIBUTOR_FIAT_ACCOUNT, {
    createFiatAccountInput: {
      ...data,
    },
  });
};

export const addUsdcAccountForDistributor = async (
  data: TAddUsdcAccount
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(ADD_DISTRIBUTOR_USDC_ACCOUNT, {
    createUsdcAccountInput: {
      ...data,
    },
  });
};

export const setActiveFiatAccountForDistributor = async (
  fiatAccountId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(SET_DISTRIBUTOR_ACTIVE_FIAT_ACCOUNT, {
    fiatAccountId,
  });
};

export const setActiveUsdcAccountForDistributor = async (
  usdcAccountId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(SET_DISTRIBUTOR_ACTIVE_USDC_ACCOUNT, {
    usdcAccountId,
  });
};

export const deleteFiatAccountForDistributor = async (
  fiatAccountId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(DELETE_DISTRIBUTOR_FIAT_ACCOUNT, {
    fiatAccountId,
  });
};

export const deleteUsdcAccountForDistributor = async (
  usdcAccountId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(DELETE_DISTRIBUTOR_USDC_ACCOUNT, {
    usdcAccountId,
  });
};
