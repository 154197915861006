import { DeviceIconSvg, TransactionIconSvg } from "../../atoms/svg";

const CustomersDrawMiniNav = ({
  currentTab,
  setCurrentTab,
}: {
  currentTab: "devices" | "transactions";
  setCurrentTab: (value: "devices" | "transactions") => void;
}) => {
  return (
    <nav className="customerDrawNav w-full flex items-center">
      <div
        className={`${currentTab === "devices" && "active"} flex flex-col items-start`}
        onClick={() => {
          setCurrentTab("devices");
        }}
      >
        <div
          className={`flex items-center gap-[5px] cursor-pointer pr-4 py-2 pl-2 hover:bg-lightYellow transition-all`}
        >
          <DeviceIconSvg className="h-[18px] w-[18px] deviceIcon" />
          <p className="text-[14px] text-[#667085] font-[500] ">Devices</p>
        </div>
        <div className="line w-[0px] h-[2px] bg-darkYellow transition-all"></div>
      </div>

      <div
        className={`${currentTab === "transactions" && "active"} flex flex-col items-start`}
        onClick={() => {
          setCurrentTab("transactions");
        }}
      >
        <div
          className={`flex items-center gap-[5px] cursor-pointer pr-4 py-2 pl-2 hover:bg-lightYellow transition-all`}
        >
          <TransactionIconSvg className="h-[18px] w-[18px] transactionIcon" />
          <p className="text-[14px] text-[#667085] font-[500] ">Transactions</p>
        </div>
        <div className="line w-[0px] h-[2px] bg-darkYellow transition-all"></div>
      </div>
    </nav>
  );
};

export default CustomersDrawMiniNav;
