import { useMutation } from "@tanstack/react-query";
import { Button, Form, Input } from "antd";
import { ReactElement, useState } from "react";
import { MutationKeys } from "../../enums/react-query";
import { signIn } from "../../services/auth/auth.service";
import { SignInRequest } from "../../services/auth/auth.types";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { PublicRoutes } from "../../types/Routes";
import { LogoSvgFull } from "../../assets/icons/LogoSvgFull";

export function SignIn(): ReactElement {
  const [valuesObj, setValueObj] = useState<SignInRequest>({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const { mutate: signInMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.SIGNIN],
    mutationFn: (values: SignInRequest) =>
      signIn(values.email.toLowerCase(), values.password),
    onSuccess: (data) => {
      // if (data?.data?.alert?.type === "error") {
      //   throw new Error(data?.data?.alert?.message);
      // } else {
      //   toast.success("OTP sent to email!");
      //   navigate(PublicRoutes.VERIFY_EMAIL, {
      //     state: { email: valuesObj.email.toLowerCase() },
      //   });
      // }
      if (data?.data?.alert?.type === "error") {
      } else {
        navigate(PublicRoutes.VERIFY_EMAIL, {
          state: { email: valuesObj.email.toLowerCase() },
        });
      }
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const onFinish = (values: SignInRequest) => {
    setValueObj(values);
    signInMutate(values);
  };
  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <div className="w-full h-full bg-[#FFFCF7] flex items-center justify-center">
      <div className="flex flex-col items-center gap-[27px] ">
        <LogoSvgFull w="150" h="150" />
        <Form
          name="basic"
          style={{
            maxWidth: 600,
          }}
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="!w-[353px] h-fit p-[20px] rounded-[8px] bg-white border-[1px] border-[#E5E7EB] flex flex-col w-full gap-[28px] "
        >
          <h6 className="Lato text-[#515151] font-[500] tracking-[1.25%] text-[20px] 2xl:text-[24px] ">
            Log into your account
          </h6>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "please input a valid email",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              className="Nunito h-[42px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] font-[300] !text-[#9CA3AF] "
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              className="-mt-[12px] Nunito h-[42px] !py-[12px] !px-[16px]  bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] font-[300] !text-[#9CA3AF] "
              placeholder="Password"
            />
          </Form.Item>

          <div className="Nunito -mt-[20px]">
            <p className="text-[14px] 2xl:text-[16px] font-[300] tracking-[1.25%] text-[#6B7280] ">
              Forget Password?{" "}
              <a
                href={PublicRoutes.FORGOT_PASSWORD}
                className="font-[600] text-darkYellow hover:text-darkYellow"
              >
                Click here
              </a>
            </p>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Nunito w-full h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow hover:opacity-[0.8] font-[500] text-[16px] 2xl:text-[18px] rounded-[8px]  "
              loading={isPending}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
