import { toast } from "sonner";
import {
  TFiatAccount,
  TPageParamInput,
  TQueryParams,
  TSortDropItem,
  TStatusDropItem,
  TUsdcAccount,
} from "../types/types";

export const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    toast.error("only JPG/PNG files allowed");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    toast.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const capitalizeWords = (str: string) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const handleParams = (params: TQueryParams) => {
  //handle sort
  const sort = params?.queryKey[1] as TSortDropItem;

  //handle filter
  const rawFilter = params?.queryKey[2] as TStatusDropItem;
  const filter =
    rawFilter.value === "All"
      ? undefined
      : (params?.queryKey[2] as TStatusDropItem);

  //handle pagination
  const rawPagination = params?.queryKey[3] as TPageParamInput | undefined;
  let pagination;
  if (rawPagination) {
    const rePagination = {
      skip: (rawPagination.pageNo - 1) * rawPagination.pageLimit,
      take: rawPagination.pageLimit,
      page: rawPagination.pageNo,
    };
    pagination = rePagination;
  } else {
    pagination = rawPagination;
  }

  //handle search
  const search = (params?.queryKey[4] as string)
    ? { key: params?.queryKey[4] as string }
    : undefined;

  return { sort, filter, pagination, search };
};

export const handleWalletAddress = (address: string) => {
  let finalStr;
  if (address && address.length > 12) {
    const firstHalf = address.slice(0, 7);
    const lastHalf = address.slice(-5);
    finalStr = firstHalf + "..." + lastHalf;
  } else {
    finalStr = address;
  }
  return finalStr;
};

export const checkIfUserAlreadyHaveAccountType = (
  check: string,
  allAccounts: TFiatAccount[] | TUsdcAccount[],
  type: "fiat" | "usdc"
) => {
  const filteredAccounts = allAccounts.filter(
    (datum: TFiatAccount | TUsdcAccount) => {
      if (type === "fiat") {
        return (datum as TFiatAccount).accountData.currency.symbol === check;
      } else {
        return (datum as TUsdcAccount).accountData.chain === check;
      }
    }
  );
  if (filteredAccounts.length > 0) {
    return true;
  }
  return false;
};

export const abbreviateString = (str: string) => {
  if (str.length <= 7) {
    return str;
  }
  const start = str.slice(0, 4);
  const end = str.slice(-3);
  return `${start}...${end}`;
};

export const truncateString = (str: string, length: number): string => {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  }
  return str;
};

export const percentageFormatter = (num: number): number => {
  if (num === 100 || num === 0) {
    return num;
  } else if (num < -100) {
    return -100;
  } else {
    return +num.toFixed(1);
  }
};

export const currencyFormatter = (
  amount: number,
  minDecimal: number = 0,
  maxDecimal: number = 1
): string =>
  new Intl.NumberFormat("en", {
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal < minDecimal ? minDecimal : maxDecimal,
  }).format(amount);
