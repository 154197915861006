import { DefaultResponse } from "../../types/default-response.dto";
import { TGraphTimeline } from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  FETCH_ACCOUNT_REVENUES,
  FETCH_AVAILABLE_BALANCES,
  FETCH_CUSTOMER_COUNT,
  FETCH_DEVICE_COUNT,
  FETCH_GRAPH_DATA,
  FETCH_REVENUE_OVERVIEW,
  FETCH_TRANSACTION_COUNT,
} from "./dashboard.queries";

export const getGraphData = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_GRAPH_DATA, {});
};

export const getAvailableBalances = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_AVAILABLE_BALANCES, {});
};

export const getRevenuesData = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_ACCOUNT_REVENUES, {});
};

export const getRevenueOverview = async (
  timeline: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_REVENUE_OVERVIEW, { timeline });
};

export const getDeviceCount = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_DEVICE_COUNT);
};

export const getCustomerCount = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_CUSTOMER_COUNT);
};

export const getTransactionCount = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_TRANSACTION_COUNT);
};
