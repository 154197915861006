import React, { ReactElement, Suspense, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { PageLoading } from "../components/atoms/PageLoading";
import Drawer from "../components/layout/Drawer/Drawer";
import Modal from "../components/layout/Modal/Modal";
import SideBar from "../components/layout/NavigationBar/SideBar";
import TopBar from "../components/layout/NavigationBar/TopBar";
import { AccessToken } from "../enums/access-token";
import Settings from "../pages/in-app/Settings";
import { ProtectedRoutes, PublicRoutes } from "../types/Routes";
import {
  getDistributor,
  getDistributorFiatAccounts,
  getDistributorUsdcAccounts,
} from "../services/distributor/distributor.service";
import { QueryKeys } from "../enums/react-query";
import { useQuery } from "@tanstack/react-query";
import { useAppStore } from "../store/store";
import { getRevenuesData } from "../services/dashboard/dashboard.service";
import { TRevenueData } from "../types/types";
import { ChangeEmail } from "../pages/auth/ChangeEmail";
import { ChangeEmailRouter } from "./ChangeEmailRouter";
import { SettingsRouter } from "./SettingsRouter";

// pages
const NotFound = React.lazy(() => import("../pages/in-app/NotFound"));
const Dashboard = React.lazy(() => import("../pages/in-app/Dashboard"));
const Customers = React.lazy(() => import("../pages/in-app/Customers"));
const PaymentPlans = React.lazy(() => import("../pages/in-app/PaymentPlans"));
const Transactions = React.lazy(() => import("../pages/in-app/Transactions"));
const Devices = React.lazy(() => import("../pages/in-app/Devices"));
const Profile = React.lazy(() => import("../pages/in-app/Profile"));
const Messages = React.lazy(() => import("../pages/in-app/Messages"));
const Notifications = React.lazy(() => import("../pages/in-app/Notifications"));
const Billing = React.lazy(() => import("../pages/in-app/Billing"));
const Account = React.lazy(() => import("../pages/in-app/Account"));

function InAppRouter(): ReactElement {
  const isAuth = localStorage.getItem(AccessToken.KEY);
  const setUserData = useAppStore((state) => state.setUserData);
  const setUserFiatAccounts = useAppStore((state) => state.setUserFiatAccounts);
  const setUserUsdcAccounts = useAppStore((state) => state.setUserUsdcAccounts);
  const setUserDataIsLoading = useAppStore(
    (state) => state.setUserDataIsLoading
  );
  const setHideAccountBalance = useAppStore(
    (state) => state.setHideAccountBalance
  );

  //get user data
  const { isLoading, error, data } = useQuery({
    queryKey: [QueryKeys.GETDISTRIBUTOR],
    queryFn: async () => {
      setUserDataIsLoading(true);
      const res = await getDistributor();
      if (res.data.payload) setUserData(res.data.payload);
      setUserDataIsLoading(false);
      return res.data;
    },
  });

  //get fiat accounts
  const {
    isLoading: fiatAccountsIsLoading,
    error: fiatAccountsError,
    data: fiatAccounts,
  } = useQuery({
    queryKey: [QueryKeys.GETDISTRIBUTORFIATACCOUNTS],
    queryFn: async () => {
      const res = await getDistributorFiatAccounts();
      if (res.data.payload) setUserFiatAccounts(res.data.payload);
      return res.data;
    },
  });

  //get usdc accounts
  const {
    isLoading: usdcAccountsIsLoading,
    error: usdcAccountsError,
    data: usdcAccounts,
  } = useQuery({
    queryKey: [QueryKeys.GETDISTRIBUTORUSDCACCOUNTS],
    queryFn: async () => {
      const res = await getDistributorUsdcAccounts();
      if (res.data.payload) setUserUsdcAccounts(res.data.payload);
      return res.data;
    },
  });

  useEffect(() => {
    //check if user is hiding their account balance
    const hideAccountBalanceFromLocal =
      localStorage.getItem("hideAccountBalance");
    if (hideAccountBalanceFromLocal) {
      setHideAccountBalance(JSON.parse(hideAccountBalanceFromLocal));
    }
  }, []);

  return (
    <Suspense fallback={<PageLoading />}>
      {isAuth ? (
        <div className="h-full w-full flex">
          <SideBar />
          <div className="flex-1 p-2 px-4 flex flex-col w-full">
            <TopBar />
            <div className="flex-1 overflow-auto">
              <Outlet />
            </div>
          </div>
          <Drawer />
          <Modal />
        </div>
      ) : (
        <Navigate to={PublicRoutes.SIGNIN} />
      )}
    </Suspense>
  );
}

export const protectedRoutes = [
  {
    path: ProtectedRoutes.BASE,
    element: <InAppRouter />,
    children: [
      {
        path: ProtectedRoutes.DASHBOARD,
        name: "Dashboard",
        element: <Dashboard />,
      },
      {
        path: ProtectedRoutes.CUSTOMERS,
        name: "Customers",
        element: <Customers />,
      },
      {
        path: ProtectedRoutes.PAYMENT_PLANS,
        name: "Payment Plans",
        element: <PaymentPlans />,
      },
      {
        path: ProtectedRoutes.TRANSACTIONS,
        name: "Transactions",
        element: <Transactions />,
      },
      {
        path: ProtectedRoutes.DEVICES,
        name: "Devices",
        element: <Devices />,
      },
      {
        path: ProtectedRoutes.SETTINGS,
        name: "Settings",
        element: <SettingsRouter />,
        children: [
          {
            path: ProtectedRoutes.PROFILE,
            name: "Profile",
            element: <Profile />,
          },
          {
            path: ProtectedRoutes.MESSAGES,
            name: "Messages",
            element: <Messages />,
          },
          {
            path: ProtectedRoutes.NOTIFICATIONS,
            name: "Notifications",
            element: <Notifications />,
          },
          {
            path: ProtectedRoutes.BILLING,
            name: "Billing",
            element: <Billing />,
          },
          {
            path: ProtectedRoutes.ACCOUNT,
            name: "Account",
            element: <Account />,
          },
        ],
      },
      {
        path: ProtectedRoutes.NOT_FOUND,
        name: "404",
        // element: <NotFound />,
        element: <Navigate to={ProtectedRoutes.DASHBOARD} />,
      },
    ],
  },
  {
    path: ProtectedRoutes.CHANGE_EMAIL,
    name: "Change Email",
    element: <ChangeEmailRouter />,
  },
];
