import { useAppStore } from "../../store/store";

import { currencyFormatter } from "../../utils/helper";

const AccountBar = ({
  icon,
  amount,
  details,
}: {
  icon: React.ReactNode;
  amount: string | number;
  details: string;
}) => {
  const hideAccountBalance = useAppStore((state) => state.hideAccountBalance);
  return (
    <div className="px-4 w-full min-h-full flex items-center justify-start gap-6 border-[1px] border-grey rounded-[8px]">
      <div className="flex items-center gap-2">
        {icon}
        {hideAccountBalance ? (
          <p className="text-black text-[20px] 2xl:text-[26px] h-full pt-[8px] tracking-[1px] align-middle">
            *****
          </p>
        ) : (
          <p className="text-black text-[14px] 2xl:text-[16px]">
            {currencyFormatter(+amount)}
          </p>
        )}
      </div>
      <p className="text-textLightGrey text-[14px] 2xl:text-[16px] capitalize">
        {details}
      </p>
    </div>
  );
};

export default AccountBar;
