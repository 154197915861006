export enum BonusTitles {
  BULK = "Bulk Payment Bonus",
  EARLY = "Early Payment Bonus",
  LOYALTY = "Loyalty Reward Bonus",
  MILESTONE = "Milestone Reward Bonus",
  USAGE = "Usage Based Bonus",
}

export interface TBonusTypesDrop {
  bonusCode: number;
  bonusType: string;
}

export interface TBonusType extends TBonusTypesDrop {
  logicData:
    | TBulkPaymentBonus
    | TEarlyPaymentBonus
    | TLoyaltyRewardBonus
    | TMilestoneRewardBonus
    | TUsageBasedBonus;
  _id?: string;
  __v?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type TBonusTypes = {}[];

export type TBulkPaymentBonus = {
  uniqueCode: number;
  bulkAmount: number;
  bonusDays: number;
};
export type TEarlyPaymentBonus = {
  uniqueCode: number;
  activationAmount?: number;
  bonusDays: number;
};
export type TLoyaltyRewardBonus = {
  uniqueCode: number;
  monthsBeforeActivation: number;
  bonusDays: number;
};
export type TMilestoneRewardBonus = {
  uniqueCode: number;
  milestoneAmount: number;
  bonusDays: number;
};
export type TUsageBasedBonus = {
  uniqueCode: number;
  noOfUsageDays: number;
  bonusDays: number;
};
