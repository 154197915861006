import { ReactElement } from "react";
import { BrowserRouter as Router } from "react-router-dom";

type Props = {
  children: ReactElement;
};

export function Layout({ children }: Props): ReactElement {
  return <Router>{children}</Router>;
}
