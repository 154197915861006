import {
  Form,
  FormInstance,
  FormListFieldData,
  InputNumber,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa6";
import { TbPigMoney } from "react-icons/tb";
import {
  BonusTitles,
  TBonusType,
  TBonusTypesDrop,
} from "../../types/payment-bonuses.types";

const ClauseSingleBox = ({
  isEditMode,
  keyI,
  name,
  fields,
  form,
  index,
  deleteClause,
  options,
  ...restField
}: {
  keyI: number;
  isEditMode: boolean;
  name: number;
  fields: FormListFieldData[];
  form: FormInstance<any>;
  index: number;
  deleteClause: (index: number | number[]) => void;
  options: TBonusTypesDrop[];
}) => {
  const [type, setType] = useState("");
  const [filterOptions, setFilteredOptions] = useState(options);
  const paymentBonuses = form.getFieldValue("paymentBonuses");

  const { Option } = Select;

  useEffect(() => {
    setFilteredOptions(options);
    if (paymentBonuses[index]) {
      //check if you should open bring out predefined fields
      setType(paymentBonuses[index].bonusType || "");

      //this is for removing selected bonuses from the options array

      const selectedOptions = paymentBonuses.map(
        (bonuses: TBonusType, index: number) => {
          return (bonuses.bonusType || "").toLowerCase();
        }
      );
      const getNewOptions = options.filter((options: TBonusTypesDrop) => {
        if (!selectedOptions.includes(options.bonusType.toLocaleLowerCase())) {
          return true;
        }
      });
      setFilteredOptions(getNewOptions);
    }
  }, [paymentBonuses]);

  return (
    <div className="w-full flex flex-col gap-[20px]" key={keyI}>
      <Form.Item
        {...restField}
        name={[name, "bonusType"]}
        rules={[{ required: true, message: "" }]}
        className="w-full"
      >
        <Select
          disabled={!isEditMode}
          placeholder="Select Clause Type"
          className="Nunito InSel h-[44px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] !text-[16px] !font-[300] !text-[#667085]"
          onChange={(e) => {
            setType(e);
            const selectedBonus = options.filter(
              (bonusObj: TBonusTypesDrop) => {
                return bonusObj.bonusType.toLowerCase() === e;
              }
            );

            //set bonus code to form
            form.setFieldsValue({
              paymentBonuses: form
                .getFieldValue("paymentBonuses")
                .map((paymentBonus: TBonusType, i: number) =>
                  i === index
                    ? {
                        ...paymentBonus,
                        bonusCode: selectedBonus[0].bonusCode,
                        logicData: {
                          ...paymentBonus.logicData,
                          uniqueCode: selectedBonus[0].bonusCode,
                        },
                      }
                    : paymentBonus
                ),
            });
          }}
        >
          {filterOptions.map(
            (
              datum: {
                bonusCode: number;
                bonusType: string;
              },
              index: number
            ) => {
              return (
                <Option
                  key={datum.bonusCode}
                  value={datum.bonusType.toLowerCase()}
                  className="!p-2 "
                >
                  <div className="flex w-full items-center gap-[5px] text-[14px]">
                    <TbPigMoney className="text-darkYellow text-[18px]" />
                    {datum.bonusType}
                  </div>
                </Option>
              );
            }
          )}
        </Select>
      </Form.Item>

      {type === BonusTitles.BULK.toLowerCase() && (
        <div className="flex gap-4 items-center">
          <Form.Item
            {...restField}
            name={[name, "logicData", "bulkAmount"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Bulk Amount"
              readOnly={!isEditMode}
            />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "logicData", "bonusDays"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Bonus Days"
              readOnly={!isEditMode}
            />
          </Form.Item>
          {isEditMode && (
            <div
              className="h-[25px] min-w-[25px] flex items-center justify-center rounded-[8px] border-[1px] !border-[#D1D5DB] rounded-[8px] cursor-pointer focus:!shadow-[0_0px_0px_1px_#ffa30094] "
              onClick={() => deleteClause(name)}
            >
              <FaMinus className="text-[#D1D5DB]" />
            </div>
          )}
        </div>
      )}

      {type === BonusTitles.EARLY.toLowerCase() && (
        <div className="flex gap-4 items-center">
          <Form.Item
            {...restField}
            name={[name, "logicData", "activationAmount"]}
            rules={[{ required: false, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Activation Amount"
              readOnly={!isEditMode}
            />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "logicData", "bonusDays"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Bonus Days"
              readOnly={!isEditMode}
            />
          </Form.Item>
          {isEditMode && (
            <div
              className="h-[25px] min-w-[25px] flex items-center justify-center rounded-[8px] border-[1px] !border-[#D1D5DB] rounded-[8px] cursor-pointer focus:!shadow-[0_0px_0px_1px_#ffa30094] "
              onClick={() => deleteClause(name)}
            >
              <FaMinus className="text-[#D1D5DB]" />
            </div>
          )}
        </div>
      )}

      {type === BonusTitles.LOYALTY.toLowerCase() && (
        <div className="flex gap-4 items-center">
          <Form.Item
            {...restField}
            name={[name, "logicData", "monthsBeforeActivation"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Months Before Activation"
              readOnly={!isEditMode}
            />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "logicData", "bonusDays"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Bonus Days"
              readOnly={!isEditMode}
            />
          </Form.Item>
          {isEditMode && (
            <div
              className="h-[25px] min-w-[25px] flex items-center justify-center rounded-[8px] border-[1px] !border-[#D1D5DB] rounded-[8px] cursor-pointer focus:!shadow-[0_0px_0px_1px_#ffa30094] "
              onClick={() => deleteClause(name)}
            >
              <FaMinus className="text-[#D1D5DB]" />
            </div>
          )}
        </div>
      )}

      {type === BonusTitles.MILESTONE.toLowerCase() && (
        <div className="flex gap-4 items-center">
          <Form.Item
            {...restField}
            name={[name, "logicData", "milestoneAmount"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Milestone Amount"
              readOnly={!isEditMode}
            />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "logicData", "bonusDays"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Bonus Days"
              readOnly={!isEditMode}
            />
          </Form.Item>
          {isEditMode && (
            <div
              className="h-[25px] min-w-[25px] flex items-center justify-center rounded-[8px] border-[1px] !border-[#D1D5DB] rounded-[8px] cursor-pointer focus:!shadow-[0_0px_0px_1px_#ffa30094] "
              onClick={() => deleteClause(name)}
            >
              <FaMinus className="text-[#D1D5DB]" />
            </div>
          )}
        </div>
      )}

      {type === BonusTitles.USAGE.toLowerCase() && (
        <div className="flex gap-4 items-center">
          <Form.Item
            {...restField}
            name={[name, "logicData", "noOfUsageDays"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="No Of Usage Days"
              readOnly={!isEditMode}
            />
          </Form.Item>
          <Form.Item
            {...restField}
            name={[name, "logicData", "bonusDays"]}
            rules={[{ required: true, message: "" }]}
            className="w-full"
          >
            <InputNumber
              min={1}
              className="Nunito InNum w-full h-[40px] flex items-center !px-[6px] bg-[#ffffff] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[14px] !font-[300] !text-[#6B7280] "
              placeholder="Bonus Days"
              readOnly={!isEditMode}
            />
          </Form.Item>
          {isEditMode && (
            <div
              className="h-[25px] min-w-[25px] flex items-center justify-center rounded-[8px] border-[1px] !border-[#D1D5DB] rounded-[8px] cursor-pointer focus:!shadow-[0_0px_0px_1px_#ffa30094] "
              onClick={() => deleteClause(name)}
            >
              <FaMinus className="text-[#D1D5DB]" />
            </div>
          )}
        </div>
      )}

      {form.getFieldValue("paymentBonuses").length > 1 &&
        form.getFieldValue("paymentBonuses").length !== index + 1 && (
          <div className="w-full h-[1px] bg-[#E6EAEE] "></div>
        )}
    </div>
  );
};

export default ClauseSingleBox;
