import { ELogoSvg } from "../../atoms/svg";

const Splash = () => {
  return (
    <div className="w-full h-full splash fixed top-0 left-0 right-0 bottom-0 m-auto z-[10000]">
      <div className="back absolute w-full h-full bg-[#00000015] z-[1]"></div>
      <div className="w-full h-full flex items-center justify-center z-[2]">
        <ELogoSvg className="eSplashLogo  w-[80px] h-[40px] xl:w-[120px] xl:h-[80px]" />
      </div>
    </div>
  );
};

export default Splash;
