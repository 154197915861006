import gql from "graphql-tag";

export const FETCH_ALL_FIAT_CURRENCIES = gql`
  query FetchAllFiatCurrencies {
    data: fetchAllFiatCurrencies {
      alert {
        type
        message
      }
      payload
    }
  }
`;
