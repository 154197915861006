import { Dropdown, Menu } from "antd";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link } from "react-router-dom";
import { ProtectedRoutes } from "../../types/Routes";
import { useAppStore } from "../../store/store";
import HideBalanceBtn from "./HideBalanceBtn";
import { json } from "stream/consumers";
import { stringify } from "querystring";

const items: { key: string; label: React.ReactNode; disabled?: boolean }[] = [
  {
    key: "1",
    disabled: true,
    label: (
      <div className="uppercase text-[14px] 2xl:text-[16px] font-[400] py-2 px-4 text-textLightGrey Nunito bg-transparent hover:bg-white cursor-default">
        <span>Accounts</span>
      </div>
    ),
  },
  {
    key: "2",
    label: (
      <Link
        to={ProtectedRoutes.ACCOUNT}
        className="!px-4 !py-2 rounded-[4px] text-[14px] 2xl:text-[16px] Nunito hover:!bg-yellow hover:!text-darkYellow transition block"
      >
        <div className="">
          <span>Add Account</span>
        </div>
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <div className="!px-4 !py-2 rounded-[4px] text-[14px] 2xl:text-[16px] Nunito hover:!bg-yellow hover:!text-darkYellow transition">
        <span>Withdraw</span>
      </div>
    ),
  },
  {
    key: "4",
    label: <HideBalanceBtn />,
  },
];

function AccountDropDown() {
  const setHideAccountBalance = useAppStore(
    (state) => state.setHideAccountBalance
  );
  const hideAccountBalance = useAppStore((state) => state.hideAccountBalance);
  const setDrawerType = useAppStore((state) => state.setDrawerType);
  const setIsDrawerOpen = useAppStore((state) => state.setIsDrawerOpen);

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      menu={{
        items,
        className: "!p-2 w-[250px] !shadow-md mt-2",
        onClick: ({ key }) => {
          if (key === "4") {
            setHideAccountBalance(!hideAccountBalance);
            localStorage.setItem(
              "hideAccountBalance",
              JSON.stringify(!hideAccountBalance)
            );
          } else if (key === "3") {
            setDrawerType("withdraw");
            setIsDrawerOpen(true);
          }
        },
      }}
    >
      <div className="h-full w-[42px] rounded-[8px] border-[1px] border-grey flex items-center justify-center cursor-pointer group/dropdown">
        <BiDotsVerticalRounded className="group-hover/dropdown:rotate-[-45deg]" />
      </div>
    </Dropdown>
  );
}

export default AccountDropDown;
