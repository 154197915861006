import React, { SetStateAction, useMemo } from "react";
import Countdown from "react-countdown";
import TimerRender from "./TimerRender";

const ResendCountDown = React.memo(
  ({
    timerRef,
    timer,
    setTimer,
    isResendPending,
    runResendOtp,
  }: {
    timerRef: any;
    timer: number;
    setTimer: React.Dispatch<SetStateAction<number>>;
    isResendPending: boolean;
    runResendOtp: () => void;
  }) => {
    return (
      <Countdown
        ref={timerRef}
        onTick={(e) => {
          if (e.total > 1000) {
            setTimer(e.total);
            localStorage.setItem("resendTimer", String(e.total));
          } else {
            setTimer(0);
            localStorage.setItem("resendTimer", String(0));
          }
        }}
        date={Date.now() + timer}
        autoStart={false}
        renderer={(props) =>
          TimerRender({ ...props, isResendPending, runResendOtp })
        }
      />
    );
  }
);

export default ResendCountDown;
