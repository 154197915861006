import { DefaultResponse } from "../../types/default-response.dto";
import { TCreatePayment, TParams } from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  CREATE_PAYMENT_PLAN,
  DELETE_PAYMENT_PLANS,
  FETCH_PAYMENT_BONUSES,
  FETCH_PAYMENT_PLAN,
  FETCH_PAYMENT_PLANS,
  UPDATE_PAYMENT_PLAN,
} from "./payment-plan.queries";

export const getPaymentPlans = async (
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_PAYMENT_PLANS, { params: { ...params } });
};

export const getPaymentPlan = async (
  paymentPlanId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_PAYMENT_PLAN, { paymentPlanId });
};

export const getPaymentPlanBonuses = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_PAYMENT_BONUSES, {});
};

export const createPaymentPlan = async (
  values: TCreatePayment
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(CREATE_PAYMENT_PLAN, {
    createPaymentPlanInput: {
      planName: values.planName,
      fullPaymentAmount: values.fullPaymentAmount,
      paymentInstalments: values.paymentInstalments,
      paymentBonuses: values.paymentBonuses,
    },
  });
};

export const updatePaymentPlan = async (
  paymentPlanId: string,
  values: TCreatePayment
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(UPDATE_PAYMENT_PLAN, {
    paymentPlanId,
    updatePaymentPlanInput: {
      planName: values.planName,
      fullPaymentAmount: values.fullPaymentAmount,
      paymentInstalments: values.paymentInstalments,
      paymentBonuses: values.paymentBonuses,
    },
  });
};

export const deletePaymentPlans = async (
  paymentPlanIds: string[]
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(DELETE_PAYMENT_PLANS, {
    paymentPlanIds,
  });
};
