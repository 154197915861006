import { toast } from "sonner";
import { AccessToken } from "../enums/access-token";
import { PublicRoutes } from "../types/Routes";
import { GraphQLErrorResponse } from "../types/graphql-error";

const timer = 2000;

export const errorParser = (err: unknown): string => {
  const errorMessage = (err as { message: string }).message;

  // Extract the JSON part of the error message
  const jsonStartIndex = errorMessage.indexOf("{");
  const jsonPart = errorMessage.substring(jsonStartIndex);

  if (jsonPart === "TypeError: Failed to fetch") {
    return "An error occurred, please check your network!";
  }

  const parsedError = JSON.parse(jsonPart) as GraphQLErrorResponse;

  console.log(
    parsedError.response?.errors[0].message ?? "Something went wrong!"
  );
  const errMsg =
    parsedError.response?.errors[0].message ?? "Something went wrong!";

  if (errMsg === "Unauthorized") {
    setTimeout(() => {
      localStorage.removeItem(AccessToken.KEY);
      window.location.href = PublicRoutes.SIGNIN;
    }, timer);
  }

  return errMsg || errorMessage;
};
