import { Dropdown, Menu } from "antd";

import { BillingSvg, ProfileSvg, SignOutSvg, TransactionIconSvg } from "./svg";
import { signOut } from "../../services/auth/auth.service";
import { useAppStore } from "../../store/store";
import { Link } from "react-router-dom";
import { ProtectedRoutes } from "../../types/Routes";

const items: { key: string; label: React.ReactNode }[] = [
  {
    key: "1",
    label: (
      <Link
        to={ProtectedRoutes.PROFILE}
        className="!py-2 rounded-[4px] text-[14px] 2xl:text-[16px] Nunito !font-[300] block px-2 transition"
      >
        <div className="flex items-center gap-2">
          <ProfileSvg className="" />
          My Profile
        </div>
      </Link>
    ),
  },
  {
    key: "2",
    label: (
      <Link
        to={ProtectedRoutes.TRANSACTIONS}
        className="!py-2 rounded-[4px] text-[14px] 2xl:text-[16px] Nunito !font-[300] block px-2 transition"
      >
        <div className="flex items-center gap-2">
          <TransactionIconSvg className="w-[18px] h-[18px]" />
          Transactions
        </div>
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <Link
        to={ProtectedRoutes.BILLING}
        className="!py-2 rounded-[4px] text-[14px] 2xl:text-[16px] Nunito !font-[300] block px-2 transition"
      >
        <div className="flex items-center gap-2">
          <BillingSvg className="" />
          Billing
        </div>
      </Link>
    ),
  },
  { key: "4", label: <div className="w-full h-[1px] bg-[#e4e7eb]"></div> },
  {
    key: "5",
    label: (
      <div className="!py-2 rounded-[4px] text-[14px] 2xl:text-[16px] Nunito !font-[300] block px-2 transition">
        <div className="flex gap-2 items-center !text-red" onClick={signOut}>
          <SignOutSvg className="" />
          Sign Out
        </div>
      </div>
    ),
  },
];

function ProfileDropDown() {
  const userData = useAppStore((state) => state.userData);
  const userDataIsLoading = useAppStore((state) => state.userDataIsLoading);

  const isLoading = userDataIsLoading && !userData;
  return (
    <Dropdown
      menu={{ items, className: "w-[200px] !shadow-md mt-2" }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <div className="h-full flex gap-[12px] items-center cursor-pointer">
        {isLoading ? (
          <div className="sk_bg w-[32px] h-[32px] rounded-full "></div>
        ) : (
          <img
            src={userData.avatar}
            alt="profile"
            className="w-[32px] h-[32px] rounded-full shadow"
          />
        )}

        <div className="flex flex-col justify-center max-md:hidden">
          {isLoading ? (
            <div className="sk_bg w-[120px] h-[13px] mb-2 "></div>
          ) : (
            <p className="text-[14px] 2xl:text-[16px] font-[500]  w-[120px] truncate">
              {userData?.fullName}
            </p>
          )}
          {isLoading ? (
            <div className="sk_bg w-[120px] h-[13px]"></div>
          ) : (
            <p className="text-[12px] 2xl:text-[14px] font-[400] text-[#475467] w-[120px] truncate ">
              {userData?.email}
            </p>
          )}
        </div>
      </div>
    </Dropdown>
  );
}

export default ProfileDropDown;
