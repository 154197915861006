import gql from "graphql-tag";

export const FETCH_TRANSACTIONS = gql`
  query FetchTransactions($params: QueryParamsInput!) {
    data: fetchTransactions(params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_TRANSACTION = gql`
  query FetchTransaction($transactionId: String!) {
    data: fetchTransaction(transactionId: $transactionId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_TRANSACTION_STATS = gql`
  query FetchTransactionStatByTimeline($lastXDays: Float!) {
    data: fetchTransactionStatByTimeline(lastXDays: $lastXDays) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const REPORT_TRANSACTION = gql`
  mutation ReportTransaction($message: String!, $transactionId: String!) {
    data: reportTransaction(message: $message, transactionId: $transactionId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;
