import { Button } from "antd";
import { zeroPad } from "react-countdown";

const TimerRender = ({
  hours,
  minutes,
  seconds,
  completed,
  api,
  isResendPending,
  runResendOtp,
}: {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
  api: any;
  isResendPending: boolean;
  runResendOtp: () => void;
}) => {
  if (completed) {
    // Render a completed state
    return (
      <Button
        type="primary"
        htmlType="submit"
        className="mt-[23px] Nunito w-full h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow disabled:hover:!bg-lightGrey hover:opacity-[0.8] font-[500] text-[16px] 2xl:text-[18px] rounded-[8px]  "
        loading={isResendPending}
        onClick={() => {
          runResendOtp();
        }}
      >
        <span>Resend Code</span>
      </Button>
    );
  } else {
    // Render a countdown
    return (
      <Button
        type="primary"
        htmlType="submit"
        className="mt-[23px] Nunito w-full h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow disabled:hover:!bg-lightGrey hover:opacity-[0.8] font-[500] text-[16px] 2xl:text-[18px] rounded-[8px]  "
        loading={isResendPending}
        disabled={true}
      >
        <span>
          Resend Code in ({zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          )
        </span>
      </Button>
    );
  }
};

export default TimerRender;
