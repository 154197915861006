import gql from "graphql-tag";

export const FETCH_CUSTOMERS = gql`
  query FetchCustomers($params: QueryParamsInput!) {
    data: fetchCustomers(params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_CUSTOMER = gql`
  query fetchCustomer($customerId: String!) {
    data: fetchCustomer(customerId: $customerId) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const ADD_CUSTOMER = gql`
  mutation AddCustomer($addCustomerInput: AddCustomerInput!) {
    data: addCustomer(addCustomerInput: $addCustomerInput) {
      alert {
        message
        type
      }
      payload
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $customerId: String!
    $updateCustomerInput: UpdateCustomerInput!
  ) {
    data: updateCustomer(
      customerId: $customerId
      updateCustomerInput: $updateCustomerInput
    ) {
      alert {
        message
        type
      }
      payload
    }
  }
`;

export const DELETE_CUSTOMERS = gql`
  mutation DeleteCustomers($customerIds: [String!]!) {
    data: deleteCustomers(customerIds: $customerIds) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_CUSTOMER_DEVICES = gql`
  query FetchCustomerDevices($customerId: String!, $params: QueryParamsInput!) {
    data: fetchCustomerDevices(customerId: $customerId, params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_CUSTOMER_TRANSACTIONS = gql`
  query FetchCustomerTransactions(
    $customerId: String!
    $params: QueryParamsInput!
  ) {
    data: fetchCustomerTransactions(customerId: $customerId, params: $params) {
      alert {
        type
        message
      }
      pagination {
        currentPage
        pageItems
        totalItems
        totalPages
        totalFilteredItems
      }
      payload
    }
  }
`;

export const FETCH_CUSTOMERS_STATS = gql`
  query FetchCustomerStats {
    data: fetchCustomerStats {
      alert {
        type
        message
      }
      payload
    }
  }
`;
