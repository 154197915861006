export enum QueryKeys {
  GETDISTRIBUTOR = "getDistributorData",
  GETDISTRIBUTORFIATACCOUNTS = "getDistributorFiatAccounts",
  GETDISTRIBUTORUSDCACCOUNTS = "getDistributorUsdcAccounts",
  GETDASHBOARDDATA = "getDashboardData",
  GETCUSTOMERTABLEDATA = "getCustomerTableData",
  GETCUSTOMERDATA = "getCustomerData",
  GETCUSTOMERDEVICES = "getCustomerDevices",
  GETCUSTOMERTRANSACTIONS = "getCustomerTransactions",
  GETPAYMENTPLANSTABLEDATA = "getPaymentPlansTableData",
  GETPAYMENTPLAN = "getPaymentPlanData",
  GETPAYMENTPLANS = "getPaymentPlans",
  GETPAYMENTPLANBONUSES = "getPaymentPlansBonuses",
  GETDEVICETABLEDATA = "getDeviceTableData",
  GETDEVICEDATA = "getDeviceData",
  GETTRANSACTIONTABLEDATA = "getTransactionTableData",
  GETTRANSACTIONDATA = "getTransactionData",
  GETGRAPHDATA = "getGraphData",
  GETTOTALREVENUES = "getTotalRevenues",
  GETREVENUEOVERVIEW = "getRevenueOverview",
  GETDEVICECOUNT = "getDeviceCount",
  GETCUSTOMERCOUNT = "getCustomerCount",
  GETTRANSACTIONCOUNT = "getTransactionCount",
  GETAVAILABLEBALANCES = "getAvailableBalances",
  GETALLFIATCURRENCIES = "getAllFiatCurrencies",
  GETTOTALDEVICESCOUNT = "getTotalDevicesCount",
  GETSOLDOUTDEVICESCOUNT = "getSoldOutDevicesCount",
  GETTRANSACTIONSSTATS = "getTransactionsStats",
  GETCUSTOMERSSTATS = "getCustomersStats",
  GETWITHDRAWALSTABLEDATA = "getWithdrawalsTableData",
  GETWITHDRAWALDATA = "getWithdrawalData",
  GETDEVICEBRANDS = "getDeviceBrands",
  GETDEVICEMODELS = "getDeviceModels",
  GETALLDEVICEMODELS = "getAllDeviceModels",
}

export enum MutationKeys {
  SIGNIN = "SignIn",
  FORGOTPASSWORD = "ForgotPassword",
  RESETPASSWORD = "ResetPassword",
  CHANGEEMAIL = "ChangeEmail",
  VERIFYOTP = "VerifyOtp",
  RESENDOTP = "ResendOtp",
  ADDCUSTOMER = "AddCustomer",
  DELETECUSTOMERS = "DeleteCustomers",
  CREATEPAYMENT = "CreatePayment",
  UPDATEPAYMENT = "UpdatePayment",
  DELETEPAYMENTPLANS = "DeletePaymentPlans",
  ADDDEVICE = "AddDevice",
  DELETEDEVICES = "DeleteDevices",
  UPDATEDEVICE = "UpdateDevices",
  REPORTTRANSACTION = "ReportTransaction",
  REPORTWITHDRAWAL = "ReportWithdrawal",
  LINKDEVICE = "linkDevice",
  UNLINKDEVICE = "unLinkDevice",
  CUSTOMERAVATARUPLOAD = "CustomerAvatarUpload",
  CUSTOMERUPDATEAVATARUPLOAD = "CustomerUpdateAvatarUpload",
  UPDATEDISTRIBUTOR = "UpdateDistributor",
  UPDATEDISTRIBUTORSENDEMAILLINK = "UpdateDistributorSendEmailLink",
  UPDATEDISTRIBUTORSENDPASSWORDLINK = "UpdateDistributorSendPasswordLink",
  DELETEDISTRIBUTOR = "DeleteDistributorAccount",
  DISTRIBUTORAVATARUPLOAD = "DistributorAvatarUpload",
  UPDATEDISTRIBUTORAVATAR = "UpdateDistributorAvatar",
  GETBANKSBYCOUNTRY = "getBanksByCountry",
  VALIDATEACCOUNT = "ValidateAccount",
  ADDFIATACCOUNT = "AddFiatAccount",
  ADDUSDCACCOUNT = "AddUsdcAccount",
  SETACTIVEFIATACCOUNT = "SetActiveFiatAccount",
  SETACTIVEUSDCACCOUNT = "SetActiveUsdcAccount",
  DELETEFIATACCOUNT = "DeleteFiatAccount",
  DELETEUSDCACCOUNT = "DeleteUsdcAccount",
  INITWITHDRAWAL = "InitWithdrawal",
}
