import { SetStateAction, useEffect, useState } from "react";
import { useDropzone, DropzoneOptions, Accept } from "react-dropzone";
import { UploadDevicesSvg, UploadSvg } from "./svg";
import { Button } from "antd";
import { BsFiletypeCsv } from "react-icons/bs";
// import { InputImgSvg } from "./svg";

const DragAndDrop = ({
  files,
  setFiles,
}: {
  files: File[] | undefined;
  setFiles: React.Dispatch<SetStateAction<File[] | undefined>>;
}) => {
  const dropZoneOptions: DropzoneOptions = {
    accept: { accept: [".csv"] },
    onDrop: (acceptedFile: any) => {
      setFiles(acceptedFile);
    },
  };

  const { getRootProps, getInputProps } = useDropzone(dropZoneOptions);

  return (
    <section className="w-full h-full bg-lightYellow border-[#E5E7EB] border-[3px] border-dashed p-[16px] rounded-[5px] cursor-pointer  ">
      <div
        {...getRootProps({
          className:
            "dropzone w-full h-full flex flex-col items-center justify-center gap-2 ",
        })}
      >
        <input {...getInputProps()} className="" accept=".csv" />
        <UploadDevicesSvg className={"w-[40px] h-[40px]"} />
        <p className="font-[600] text-[14px] text-[#6B7280] ">
          Click to upload or drag and drop
        </p>
        {(files as File[]) && (files as File[]).length > 0 && (
          <div className="flex gap-2 items-center">
            <BsFiletypeCsv />{" "}
            <p className="font-[400] text-[12px] text-[#6B7280] ">
              {(files as File[])[0].name}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragAndDrop;
