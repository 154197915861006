import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./Protected";
import { publicRoutes } from "./Public";

const AppRouter = () => {
  const routes = useRoutes([...publicRoutes, ...protectedRoutes]);
  return routes;
};

export default AppRouter;
