import { DefaultResponse } from "../../types/default-response.dto";
import { TAddCustomer, TParams } from "../../types/types";
import { createAuthClient } from "../auth/auth.client";
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMERS,
  FETCH_CUSTOMER,
  FETCH_CUSTOMERS,
  FETCH_CUSTOMERS_STATS,
  FETCH_CUSTOMER_DEVICES,
  FETCH_CUSTOMER_TRANSACTIONS,
  UPDATE_CUSTOMER,
} from "./customer.queries";

export const getCustomers = async (
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_CUSTOMERS, { params: { ...params } });
};

export const getCustomer = async (
  customerId: string
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_CUSTOMER, {
    customerId,
  });
};

export const addCustomers = async (
  data: TAddCustomer
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(ADD_CUSTOMER, {
    addCustomerInput: {
      address: data.address,
      avatar: data.avatar,
      country: data.country,
      devices: data.devices,
      email: data.email,
      fullName: data.fullName,
      gender: data.gender,
      phone: data.phone,
      wallet: data.wallet,
    },
  });
};

export const updateCustomer = async (
  customerId: string,
  data: TAddCustomer
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(UPDATE_CUSTOMER, {
    customerId,
    updateCustomerInput: {
      address: data.address,
      avatar: data.avatar,
      country: data.country,
      devices: data.devices,
      email: data.email,
      fullName: data.fullName,
      gender: data.gender,
      phone: data.phone,
      wallet: data.wallet,
    },
  });
};

export const deleteCustomers = async (
  customerIds: string[]
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(DELETE_CUSTOMERS, {
    customerIds,
  });
};

export const getCustomerDevices = async (
  customerId: string,
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_CUSTOMER_DEVICES, {
    customerId,
    params,
  });
};

export const getCustomerTransactions = async (
  customerId: string,
  params?: TParams
): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_CUSTOMER_TRANSACTIONS, {
    customerId,
    params,
  });
};

export const getCustomerStats = async (): Promise<DefaultResponse> => {
  const authClient = createAuthClient();
  return authClient.request(FETCH_CUSTOMERS_STATS, {});
};
