import gql from "graphql-tag";

export const FETCH_GRAPH_DATA = gql`
  query FetchGraphData {
    data: fetchGraphData {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_AVAILABLE_BALANCES = gql`
  query FetchAvailableBalances {
    data: fetchAvailableBalances {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_ACCOUNT_REVENUES = gql`
  query FetchAccountRevenues {
    data: fetchAccRevenues {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_REVENUE_OVERVIEW = gql`
  query FetchRevenueOverview($timeline: String!) {
    data: fetchRevenueOverviewData(timeline: $timeline) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const FETCH_DEVICE_COUNT = gql`
  query FetchDeviceCount {
    data: fetchDeviceCount {
      alert {
        type
        message
      }
      payload
    }
  }
`;
export const FETCH_CUSTOMER_COUNT = gql`
  query FetchCustomerCount {
    data: fetchCustomerCount {
      alert {
        type
        message
      }
      payload
    }
  }
`;
export const FETCH_TRANSACTION_COUNT = gql`
  query FetchTransactionCount {
    data: fetchTransactionCount {
      alert {
        type
        message
      }
      payload
    }
  }
`;
