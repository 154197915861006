import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, InputNumber } from "antd";
import { useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { toast } from "sonner";
import { MutationKeys, QueryKeys } from "../../../enums/react-query";
import {
  getAllDeviceModels,
  getDevice,
  updateDevice,
} from "../../../services/devices/devices.service";
import { getPaymentPlans } from "../../../services/payment-plans/payment-plan.service";
import { useAppStore } from "../../../store/store";
import { DeviceStatus, TAddDevice } from "../../../types/types";
import DeleteBtn from "../../atoms/DeleteBtn";
import PlanDropDown from "../../atoms/PlanDropDown";
import { DeviceIconSvg } from "../../atoms/svg";

const DevicesDrawer = () => {
  const setIsDrawerOpen = useAppStore((state) => state.setIsDrawerOpen);
  const drawerId = useAppStore((state) => state.drawerId);

  const setModalIsOpen = useAppStore((state) => state.setModalIsOpen);
  const setModalType = useAppStore((state) => state.setModalType);
  const setModalData = useAppStore((state) => state.setModalData);

  const [initialValues, setInitialValues] = useState();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const {
    isLoading: paymentPlanIsLoading,
    error: paymentPlanError,
    data: paymentPlanData,
  } = useQuery({
    queryKey: [QueryKeys.GETPAYMENTPLANSTABLEDATA],
    queryFn: async () => {
      const res = await getPaymentPlans();
      return res.data;
    },
  });

  const {
    isLoading: deviceModelsIsLoading,
    error: deviceModelsError,
    data: deviceModelsData,
  } = useQuery({
    queryKey: [QueryKeys.GETALLDEVICEMODELS],
    queryFn: async () => {
      const res = await getAllDeviceModels();
      return res.data;
    },
  });

  const { isLoading, error, data } = useQuery({
    queryKey: [QueryKeys.GETDEVICEDATA, drawerId],
    queryFn: async () => {
      const res = await getDevice(drawerId);
      form.setFieldsValue(res.data.payload);
      setInitialValues(res.data.payload);
      return res.data;
    },
  });

  const { mutate: updateDeviceMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.UPDATEDEVICE],
    mutationFn: (values: TAddDevice) =>
      updateDevice(data?.payload._id, { ...values }),
    onSuccess: (data) => {
      setIsDrawerOpen(false);
      queryClient.invalidateQueries({
        queryKey: [`${QueryKeys.GETDEVICETABLEDATA}`],
      });
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const paymentPlanValue = Form.useWatch("paymentPlan", form);
  const deviceModelValue = Form.useWatch("deviceModel", form);

  const handlePaymentPlanChange = (value: string) => {
    form.setFieldsValue({ paymentPlan: value });
  };
  const handlePaymentPlanCostChange = (value: number) => {
    form.setFieldsValue({ totalCost: value });
  };
  const handleDeviceModelChange = (value: string) => {
    form.setFieldsValue({ deviceModel: value });
  };

  // const dropOptions = [{ name: "Custom total amount" }];

  const onFinish = (values: any) => {
    updateDeviceMutate(values);
  };

  const deleteBtnAction = () => {
    if (data?.payload.status === DeviceStatus.PURCHASED) {
      toast.warning(
        "This Device is currently in use and therefore cannot be deleted!"
      );
    } else {
      setModalType("deleteDevice");
      setModalIsOpen(true);
      setModalData([data?.payload._id]);
    }
  };

  return (
    <div className="w-full h-full">
      <div className="w-full bg-yellow h-[80px] md:h-[100px] flex items-center justify-center">
        <h5 className="text-[15px] 2xl:text-[20px] font-[600] text-darkYellow uppercase flex items-center">
          Device S/N: {data?.payload.serialNumber}
        </h5>
      </div>

      <div className="actionButtons flex flex-col w-full gap-[10px] pt-[17px] md:pt-[17px] px-[30px] md:px-[58px] ">
        <div
          className={`w-full transition-all overflow-hidden flex gap-[15px] items-center justify-end`}
        >
          <DeleteBtn action={deleteBtnAction} className={"!h-[40px]"} />
        </div>
        <div className="h-[1px] w-full bg-[#E6EAEE]"></div>
      </div>

      <Form
        name="devices"
        initialValues={{}}
        onFinish={onFinish}
        form={form}
        // onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
        requiredMark={false}
        className="Nunito rounded-[8px] flex flex-col w-full h-full  "
      >
        <div className="flex flex-col w-full py-[25px] md:py-[39px] px-[30px] md:px-[58px] h-fit bg-white gap-[24px]">
          {data?.payload.status === DeviceStatus.PURCHASED && (
            <div className="-mt-[20px] flex flex-row items-center gap-[4px]">
              <IoIosWarning className="text-[18px] text-darkYellow" />
              <p className="text-darkYellow text-[14px] leading-[1.2] mt-[1px]">
                Devices that are currently in use cannot be edited!
              </p>
            </div>
          )}
          <Form.Item
            name="deviceModel"
            rules={[
              {
                required: true,
                message: "Please select Device model!",
              },
            ]}
          >
            <div className="flex w-full flex-col ">
              <p className="Nunito pb-[6px] text-[#344054] text-[14px] font-[400] leading-[20px] ">
                Device Model
              </p>
              {deviceModelsIsLoading ? (
                <div className="sk_bg rounded-[8px] w-full h-[44px]"></div>
              ) : (
                <PlanDropDown
                  placeholder="Device model"
                  options={deviceModelsData?.payload || []}
                  state={deviceModelValue}
                  setState={handleDeviceModelChange}
                  setPlanCost={() => {}}
                  readOnly={data?.payload.status === DeviceStatus.PURCHASED}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="serialNumber"
            label="Serial number"
            tooltip="device serial number"
            rules={[
              {
                required: true,
                message: "Please input a serial number!",
              },
            ]}
          >
            {isLoading ? (
              <div className="sk_bg rounded-[8px] w-full h-[44px]"></div>
            ) : (
              <Input
                className="Nunito h-[44px] w-full !py-[8px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="345256THTR"
                readOnly={data?.payload.status === DeviceStatus.PURCHASED}
              />
            )}
          </Form.Item>
          <Form.Item
            name="startingCode"
            label="Starting code"
            tooltip="Device starting code"
            rules={[
              {
                required: true,
                message: "Please input a starting code!",
              },
            ]}
          >
            {isLoading ? (
              <div className="sk_bg rounded-[8px] w-full h-[44px]"></div>
            ) : (
              <Input
                className="Nunito h-[44px] w-full !py-[8px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="12332323333"
                readOnly={data?.payload.status === DeviceStatus.PURCHASED}
              />
            )}
          </Form.Item>
          <Form.Item
            name="key"
            label="Key"
            tooltip="Device Activation Key"
            rules={[
              {
                required: true,
                message: "Please input a key!",
              },
            ]}
          >
            {isLoading ? (
              <div className="sk_bg rounded-[8px] w-full h-[44px]"></div>
            ) : (
              <Input
                className="Nunito h-[44px] w-full !py-[8px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
                placeholder="f96523e14219f248d4181290f8c98b23"
                readOnly={data?.payload.status === DeviceStatus.PURCHASED}
              />
            )}
          </Form.Item>
          <Form.Item
            name="count"
            label="Count"
            tooltip="Device count"
            rules={[
              {
                required: true,
                message: "Please input a count!",
              },
            ]}
          >
            {isLoading ? (
              <div className="sk_bg rounded-[8px] w-full h-[44px]"></div>
            ) : (
              <InputNumber
                min={0}
                className="Nunito w-full h-[44px] flex items-center !px-[4px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] !font-[300] !text-[#667085] "
                placeholder="Count"
                readOnly={data?.payload.status === DeviceStatus.PURCHASED}
              />
            )}
          </Form.Item>

          <Form.Item
            name="paymentPlan"
            rules={[
              {
                required: true,
                message: "Please select payment plan!",
              },
            ]}
          >
            <div className="flex w-full flex-col ">
              <p className="Nunito pb-[6px] text-[#344054] text-[14px] font-[400] leading-[20px] ">
                Payment plan
              </p>
              {paymentPlanIsLoading ? (
                <div className="sk_bg rounded-[8px] w-full h-[44px]"></div>
              ) : (
                <PlanDropDown
                  placeholder="Payment Plan"
                  options={paymentPlanData?.payload || []}
                  state={paymentPlanValue}
                  setState={handlePaymentPlanChange}
                  setPlanCost={handlePaymentPlanCostChange}
                  readOnly={data?.payload.status === DeviceStatus.PURCHASED}
                />
              )}
            </div>
          </Form.Item>
          {paymentPlanValue && (
            <Form.Item
              name="totalCost"
              label="Total Cost"
              rules={[
                {
                  required: true,
                  message: "Please input total cost!",
                },
              ]}
            >
              <Input
                className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] cursor-not-allowed "
                placeholder="Key"
                readOnly={true}
              />
            </Form.Item>
          )}

          <div className="flex gap-[14px] items-center justify-end mt-[26px]">
            <Button
              type="primary"
              htmlType="button"
              className="Nunito w-full max-w-[127px] h-[40px] flex items-center justify-center bg-[#EFEFEF] hover:!bg-[#EFEFEF] !text-[#6B7280] hover:!text-[#6B7280] hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
              loading={false}
              onClick={() => setIsDrawerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="Nunito w-full max-w-[127px] h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow disabled:hover:!bg-[#EFEFEF] hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
              loading={false}
              disabled={data?.payload.status === DeviceStatus.PURCHASED}
            >
              Update
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DevicesDrawer;
