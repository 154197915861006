import { ReactElement, Suspense } from "react";
import { AccessToken } from "../enums/access-token";
import { Navigate } from "react-router-dom";
import { PublicRoutes } from "../types/Routes";
import { PageLoading } from "../components/atoms/PageLoading";
import { ChangeEmail } from "../pages/auth/ChangeEmail";

export function ChangeEmailRouter(): ReactElement {
  const isAuth = localStorage.getItem(AccessToken.KEY);

  const saveCallBackUrlAndNavigate = () => {
    localStorage.setItem("callbackUrl", window.location.pathname);
    return <Navigate to={PublicRoutes.SIGNIN} />;
  };

  return (
    <Suspense fallback={<PageLoading />}>
      {isAuth ? <ChangeEmail /> : saveCallBackUrlAndNavigate()}
    </Suspense>
  );
}
