import gql from "graphql-tag";

// export const SIGN_IN_MUTATION = gql`
//   mutation SignIn($email: String!, $password: String!) {
//     accessToken: signIn(signInInput: { email: $email, password: $password }) {
//       token
//     }
//   }
// `;

export const SIGN_IN_MUTATION = gql`
  mutation SignIn($signInInput: SignInInput!) {
    data: signIn(signInInput: $signInInput) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const VERIFY_OTP_MUTATION = gql`
  mutation VerifyOtp($otpCode: String!, $email: String!) {
    data: verifyOtp(otpCode: $otpCode, email: $email) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const RESEND_OTP_MUTATION = gql`
  mutation ResendOtp($email: String!) {
    data: resendOtp(email: $email) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const SEND_FORGOT_PASSWORD_LINK = gql`
  mutation ForgotPassword($email: String!) {
    data: forgotPassword(email: $email) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const CHANGE_PASSWORD_FROM_FORGOT_PASSWORD = gql`
  mutation ResetPassword($password: String!) {
    data: resetPassword(password: $password) {
      alert {
        type
        message
      }
      payload
    }
  }
`;

export const CHANGE_EMAIL_FROM_RESET_EMAIL = gql`
  mutation UpdateEmail($newEmail: String!) {
    data: updateEmail(newEmail: $newEmail) {
      alert {
        type
        message
      }
      payload
    }
  }
`;
