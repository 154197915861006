import { ReactElement, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ProtectedRoutes } from "../types/Routes";
import Settings from "../pages/in-app/Settings";

export function SettingsRouter(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === ProtectedRoutes.SETTINGS ||
      location.pathname === "/settings/"
    ) {
      navigate(ProtectedRoutes.PROFILE);
    }
  }, []);
  return (
    <div className="w-full">
      <Settings />
      <Outlet />
    </div>
  );
}
