import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, InputNumber, Tag } from "antd";
import { MutationKeys, QueryKeys } from "../../../enums/react-query";
import {
  addDevice,
  getDeviceBrands,
  getDeviceModelsByBrands,
} from "../../../services/devices/devices.service";
import { getPaymentPlans } from "../../../services/payment-plans/payment-plan.service";
import { useAppStore } from "../../../store/store";
import { TAddDevice } from "../../../types/types";
import PlanDropDown from "../../atoms/PlanDropDown";
import { useState } from "react";

const AddDeviceDraw = () => {
  const setIsDrawerOpen = useAppStore((state) => state.setIsDrawerOpen);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const paymentPlanValue = Form.useWatch("paymentPlan", form);
  const deviceBrandValue = Form.useWatch("deviceBrand", form);
  const [isModelQueryActivated, setIsModelQueryActivated] = useState(false);
  const deviceModelValue = Form.useWatch("deviceModel", form);

  const {
    isLoading: paymentPlanIsLoading,
    error,
    data: paymentPlanData,
  } = useQuery({
    queryKey: [QueryKeys.GETPAYMENTPLANSTABLEDATA],
    queryFn: async () => {
      const res = await getPaymentPlans();
      return res.data;
    },
  });

  const {
    isLoading: deviceBrandsIsLoading,
    error: deviceBrandsError,
    data: deviceBrandsData,
  } = useQuery({
    queryKey: [QueryKeys.GETDEVICEBRANDS],
    queryFn: async () => {
      const res = await getDeviceBrands();
      return res.data;
    },
  });

  const {
    isLoading: deviceModelsIsLoading,
    error: deviceModelsError,
    data: deviceModelsData,
  } = useQuery({
    queryKey: [QueryKeys.GETDEVICEMODELS, deviceBrandValue],
    enabled: isModelQueryActivated, // query will not run automatically
    queryFn: async () => {
      const res = await getDeviceModelsByBrands(deviceBrandValue);
      return res.data;
    },
  });

  const { mutate: addDeviceMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.ADDDEVICE],
    mutationFn: (values: TAddDevice) => addDevice({ ...values }),
    onSuccess: (data) => {
      setIsDrawerOpen(false);
      queryClient.invalidateQueries({
        queryKey: [`${QueryKeys.GETDEVICETABLEDATA}`],
      });
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const onFinish = (values: any) => {
    addDeviceMutate(values);
  };

  const handlePaymentPlanChange = (value: string) => {
    form.setFieldsValue({ paymentPlan: value });
  };
  const handlePaymentPlanCostChange = (value: number) => {
    form.setFieldsValue({ totalCost: value });
  };
  const handleDeviceBrandChange = (value: string) => {
    form.setFieldsValue({ deviceBrand: value });
    setIsModelQueryActivated(true);
  };
  const handleDeviceModelChange = (value: string) => {
    form.setFieldsValue({ deviceModel: value });
  };

  const customizeRequiredMark = (label: any, prop: any) => (
    <>
      {prop?.required ? (
        <Tag color="error" className="Nunito">
          Required
        </Tag>
      ) : (
        <Tag color="warning" className="Nunito">
          optional
        </Tag>
      )}
      {label}
    </>
  );

  return (
    <div className="w-full h-full">
      <div className="w-full bg-yellow h-[80px] md:h-[100px] flex items-center justify-center">
        <h5 className="text-[15px] 2xl:text-[20px] font-[600] text-darkYellow uppercase">
          Add New Device
        </h5>
      </div>
      <Form
        name="addDevice"
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
        requiredMark={customizeRequiredMark}
        className="Nunito h-fit py-[25px] md:py-[39px] px-[30px] md:px-[58px] rounded-[8px] bg-white flex flex-col w-full gap-[24px] "
      >
        <Form.Item
          name="deviceBrand"
          label="Device Brand"
          rules={[
            {
              required: true,
              message: "Please select Device brand!",
            },
          ]}
        >
          <PlanDropDown
            placeholder="Device brand"
            options={deviceBrandsData?.payload || []}
            state={deviceBrandValue}
            setState={handleDeviceBrandChange}
            setPlanCost={() => {}}
          />
        </Form.Item>
        {deviceBrandValue && (
          <Form.Item
            name="deviceModel"
            label="Device Model"
            rules={[
              {
                required: true,
                message: "Please select Device model!",
              },
            ]}
          >
            <PlanDropDown
              placeholder="Device model"
              options={deviceModelsData?.payload || []}
              state={deviceModelValue}
              setState={handleDeviceModelChange}
              setPlanCost={() => {}}
            />
          </Form.Item>
        )}
        <Form.Item
          name="serialNumber"
          label="Serial Number"
          rules={[
            {
              required: true,
              message: "Please input a serial number!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="Serial number"
            readOnly={paymentPlanIsLoading}
          />
        </Form.Item>
        <Form.Item
          name="startingCode"
          label="Starting Code"
          rules={[
            {
              required: true,
              message: "Please input a starting code!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="Starting Code"
            readOnly={paymentPlanIsLoading}
          />
        </Form.Item>
        <Form.Item
          name="key"
          label="Key"
          rules={[
            {
              required: true,
              message: "Please input a Key!",
            },
          ]}
        >
          <Input
            className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] "
            placeholder="Key"
            readOnly={paymentPlanIsLoading}
          />
        </Form.Item>
        <Form.Item
          name="count"
          label="Count"
          rules={[
            {
              required: true,
              message: "Please input a count!",
            },
          ]}
        >
          <InputNumber
            min={0}
            className="Nunito w-full h-[44px] flex items-center !px-[4px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus-within:!shadow-[0_0px_0px_1px_#ffa30094] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] !font-[300] !text-[#667085] "
            placeholder="Count"
            readOnly={paymentPlanIsLoading}
          />
        </Form.Item>
        <Form.Item
          name="paymentPlan"
          label="Payment Plans"
          rules={[
            {
              required: true,
              message: "Please select payment plan!",
            },
          ]}
        >
          <PlanDropDown
            placeholder="Payment Plan"
            options={paymentPlanData?.payload || []}
            state={paymentPlanValue}
            setState={handlePaymentPlanChange}
            setPlanCost={handlePaymentPlanCostChange}
          />
        </Form.Item>
        {paymentPlanValue && (
          <Form.Item
            name="totalCost"
            label="Total Cost"
            rules={[
              {
                required: true,
                message: "Please input total cost!",
              },
            ]}
          >
            <Input
              className="Nunito h-[44px] !py-[12px] !px-[16px] bg-[#F9FAFB] border-[1px] !border-[#D1D5DB] focus:!shadow-[0_0px_0px_1px_#ffa30094] rounded-[8px] text-[16px] font-[300] !text-[#667085] cursor-not-allowed "
              placeholder="Key"
              readOnly={true}
            />
          </Form.Item>
        )}
        <div className="flex gap-[14px] items-center justify-end">
          <Button
            type="primary"
            htmlType="button"
            className="Nunito w-full max-w-[127px] h-[40px] flex items-center justify-center bg-[#EFEFEF] hover:!bg-[#EFEFEF] !text-[#6B7280] hover:!text-[#6B7280] hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
            loading={false}
            onClick={() => setIsDrawerOpen(false)}
          >
            Cancel
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="Nunito w-fit min-w-[120px] h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow hover:opacity-[0.8] font-[600] text-[14px] 2xl:text-[16px] rounded-[8px]  "
              loading={isPending}
            >
              Add Device
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddDeviceDraw;
