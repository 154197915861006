import { ReactElement } from "react";
import Splash from "../layout/Splash/Splash";

export function PageLoading(): ReactElement {
  return (
    <>
      <Splash />
    </>
  );
}
