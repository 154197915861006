import { Link } from "react-router-dom";
import { useAppStore } from "../../../store/store";
import AccountDropDown from "../../atoms/AccountDropDown";
import ProfileDropDown from "../../atoms/ProfileDropDown";
import TopBarAccountBar from "../../atoms/TopBarAccountBar";
import { MessageSvg } from "../../atoms/svg";
import { ProtectedRoutes } from "../../../types/Routes";

function TopBar() {
  const mobileMenuIsOpen = useAppStore((state) => state.mobileMenuIsOpen);
  return (
    <div className="w-full max-md:h-fit max-md:pt-[24px] h-[73px] flex flex-col justify-end gap-[12px]">
      <div className={` flex w-full gap-2 justify-between items-center h-fit`}>
        <div className="hidden max-md:flex"></div>
        <div
          className={`${mobileMenuIsOpen && "!ml-[0px]"} transition-all max-xl:ml-[50px] max-md:hidden flex gap-2 h-full items-center `}
        >
          <TopBarAccountBar />
          <AccountDropDown />
        </div>
        <div className="flex items-center h-full gap-4 ">
          <Link to={ProtectedRoutes.MESSAGES}>
            <MessageSvg className="cursor-pointer" />
          </Link>
          <div className="divider w-[1px] h-[60%] bg-[#D1D5DB]" />
          <ProfileDropDown />
        </div>
      </div>
      <div className="w-[100%] mx-auto h-[1px] bg-lightGrey max-md:hidden" />
      {/* this is for mobile and tab view */}
      <div
        className={` transition-all max-md:flex hidden gap-2 h-full items-center justify-between  `}
      >
        <TopBarAccountBar />
        <AccountDropDown />
      </div>
    </div>
  );
}

export default TopBar;
