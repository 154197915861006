import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { Register } from "../pages/auth/Register";
import { ResetPassword } from "../pages/auth/ResetPassword";
import { SignIn } from "../pages/auth/SignIn";
import { VerifyEmail } from "../pages/auth/VerifyEmail";
import { ProtectedRoutes, PublicRoutes } from "../types/Routes";
import { AccessToken } from "../enums/access-token";
import { ChangeEmail } from "../pages/auth/ChangeEmail";

function AuthRouter(): ReactElement {
  const isAuth = localStorage.getItem(AccessToken.KEY);
  return isAuth ? <Navigate to={ProtectedRoutes.BASE} /> : <Outlet />;
}

export const publicRoutes = [
  {
    path: PublicRoutes.AUTH,
    element: <AuthRouter />,
    children: [
      { path: PublicRoutes.SIGNIN, name: "SignIn", element: <SignIn /> },
      { path: PublicRoutes.REGISTER, name: "Register", element: <Register /> },
      {
        path: PublicRoutes.VERIFY_EMAIL,
        name: "Verify Email",
        element: <VerifyEmail />,
      },
      {
        path: PublicRoutes.FORGOT_PASSWORD,
        name: "Forgot Password",
        element: <ForgotPassword />,
      },
      {
        path: PublicRoutes.RESET_PASSWORD,
        name: "Reset Password",
        element: <ResetPassword />,
      },
    ],
  },
];
