import { Button } from "antd";
import {
  LegacyRef,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import OTPInput from "../../components/Auth/OTPInput";
import { useMutation } from "@tanstack/react-query";
import { MutationKeys } from "../../enums/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { resendOtp, verifyOtp } from "../../services/auth/auth.service";
import Countdown, { zeroPad } from "react-countdown";
import Splash from "../../components/layout/Splash/Splash";
import { ProtectedRoutes } from "../../types/Routes";
import { AccessToken } from "../../enums/access-token";
import { toast } from "sonner";
import { LogoSvgFull } from "../../assets/icons/LogoSvgFull";
import TimerRender from "../../components/Auth/TimerRender";
import ResendCountDown from "../../components/Auth/ResendCountDown";

let defaultTimer = 30000;
export function VerifyEmail(): ReactElement {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [restart, setRestart] = useState(true);
  const timerFromStore = localStorage.getItem("resendTimer");
  const [timer, setTimer] = useState<number>(defaultTimer);
  const timerRef = useRef<Countdown>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [splashIsActive, setSplashIsActive] = useState(false);

  const { mutate: verifyOTPMutate, isPending } = useMutation({
    mutationKey: [MutationKeys.VERIFYOTP],
    mutationFn: () =>
      verifyOtp(location?.state?.email, otp.join().replaceAll(",", "")),
    onSuccess: (data) => {
      if (data?.data?.alert?.type === "error") {
      } else {
        localStorage.removeItem("resendTimer");
        localStorage.removeItem("timerStage");
        timerRef?.current?.stop();
        const { token } = data.data.payload;
        localStorage.setItem(AccessToken.KEY, token);
        setSplashIsActive(true);
        //route into app
        setTimeout(() => {
          //check if theres a call back waiting for auth
          const callbackUrl = localStorage.getItem("callbackUrl");
          localStorage.removeItem("callbackUrl");
          navigate(callbackUrl ? callbackUrl : ProtectedRoutes.DASHBOARD);
        }, 5000);
      }
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const { mutate: resendOTPMutate, isPending: isResendPending } = useMutation({
    mutationKey: [MutationKeys.RESENDOTP],
    mutationFn: () => {
      return resendOtp(location?.state?.email);
    },
    onSuccess: (data) => {
      if (data?.data?.alert?.type === "success") {
        const timerStage = localStorage.getItem("timerStage");
        localStorage.setItem(
          "timerStage",
          timerStage ? String(Number(timerStage) + 1) : String(2)
        );
        setTimer(defaultTimer * (timerStage ? Number(timerStage) + 1 : 2));
      }
    },
    onError: (error) => {},
  });

  const runResendOtp = () => {
    resendOTPMutate();
  };

  useEffect(() => {
    if (!location?.state?.email) {
      navigate("/auth/signin");
    }
  }, []);

  useEffect(() => {
    if (restart) {
      timerRef?.current?.start();
    }
  }, [restart, timer]);

  useEffect(() => {
    setTimer(Number(timerFromStore) || defaultTimer);
  }, []);

  // console.log(timer);

  return (
    <div className="w-full h-full bg-[#FFFCF7] flex items-center justify-center">
      {splashIsActive && <Splash />}
      <div className="flex flex-col items-center gap-[27px] ">
        <LogoSvgFull w="150" h="150" />
        <div className="!w-[353px] h-fit p-[20px] rounded-[8px] bg-white border-[1px] border-[#E5E7EB] flex flex-col w-full ">
          <h6 className="Lato text-[#515151] font-[500] tracking-[1.25%] text-[20px] 2xl:text-[24px] ">
            Enter OTP verification code
          </h6>
          <p className="mt-[6px] text-[#6B7280] font-[300] tracking-[1.25%] text-[14px] 2xl:text-[16px]">
            To login, kindly enter the code we sent to your email
          </p>
          <div className="flex w-full h-fit mt-[23px]">
            <OTPInput otp={otp} setOtp={setOtp} />
          </div>

          <Button
            type="primary"
            htmlType="submit"
            className="mt-[23px] Nunito w-full h-[40px] flex items-center justify-center bg-darkYellow hover:!bg-darkYellow disabled:hover:!bg-lightGrey hover:opacity-[0.8] font-[500] text-[16px] 2xl:text-[18px] rounded-[8px]  "
            loading={isPending}
            disabled={otp.join().replaceAll(",", "").length < 6}
            onClick={() => verifyOTPMutate()}
          >
            Login to your account
          </Button>

          <ResendCountDown
            timerRef={timerRef}
            timer={timer}
            setTimer={setTimer}
            isResendPending={isResendPending}
            runResendOtp={runResendOtp}
          />

          <div className="Nunito mt-[12px] flex justify-center">
            <p className="text-[14px] 2xl:text-[16px] font-[300] tracking-[1.25%] text-[#6B7280] ">
              Already have an account?{" "}
              <a
                href="/auth/signin"
                className="font-[600] text-darkYellow hover:text-darkYellow"
              >
                Click here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
