import { QueryClientProvider } from "@tanstack/react-query";
import { ReactElement } from "react";
import AppRouter from "../../routes/AppRouter";
import { queryClient } from "../../utils/react-query";

export function AppProvider(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
    </QueryClientProvider>
  );
}
