import { useRef, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

import AccountBar from "./AccountBar";
import { QueryKeys } from "../../enums/react-query";
import { getAvailableBalances } from "../../services/dashboard/dashboard.service";
import { TAccBalanceData } from "../../types/types";
import { truncateString, abbreviateString } from "../../utils/helper";

function TopBarAccountBar() {
  const [stage, setStage] = useState(false);
  const swiperRef = useRef<SwiperCore | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const { isLoading, data } = useQuery({
    queryKey: [QueryKeys.GETAVAILABLEBALANCES],
    queryFn: async () => {
      const res = await getAvailableBalances();
      const data = res.data.payload as TAccBalanceData[];
      return res.data;
    },
  });

  const parseDetails = (rev: TAccBalanceData) => {
    let accName = "";
    let accNumber = "";

    if (rev.account && "accName" in rev.account.accountData) {
      accName = rev.account.accountData.bank;
      accNumber = rev.account.accountData.accNumber;
    } else if (rev.account && "chain" in rev.account.accountData) {
      accName = rev.account?.accountData.chain;
      accNumber = rev.account.accountData.publicKey;
    } else {
      return "No account available";
    }

    return `${abbreviateString(accNumber)}, ${truncateString(accName, 10)}`;
  };

  return (
    <div className="relative ">
      <p className="absolute left-0 top-[-1rem] text-[11px] text-textGrey z-[100]">
        Avail Bal:
      </p>
      <div className="max-md:w-full w-[342px] h-[42px] overflow-hidden relative">
        <div
          className={`h-full w-full flex flex-col gap-2 transition duration-300 ${
            stage ? "translate-y-[-120%]" : "translate-y-[0%]"
          }`}
        >
          {isLoading ? (
            <div className="sk_bg w-full h-[42px] rounded-[8px] border-grey"></div>
          ) : (
            <Swiper
              modules={[Navigation]}
              slidesPerView={1}
              direction="vertical"
              onSwiper={(swiper: any) => {
                swiperRef.current = swiper;
              }}
              onSlideChange={(swiper: SwiperCore) => {
                setActiveIndex(swiper.activeIndex);
              }}
              className="w-[342px] h-[42px]"
            >
              {data?.payload?.map((bal: TAccBalanceData, ind: number) => (
                <SwiperSlide key={ind}>
                  <AccountBar
                    icon={
                      <div className="h-[18px] w-[18px] rounded-full overflow-hidden">
                        <img
                          src={bal.currencyIcon}
                          alt="Eazipower Currency"
                          className="w-full h-full"
                        />
                      </div>
                    }
                    amount={bal.availableBalance}
                    details={parseDetails(bal)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        {!isLoading && (
          <div
            className="h-[24px] w-[24px] flex flex-col items-center justify-center cursor-pointer shadow rounded-[8px] absolute m-auto top-0 bottom-0 right-4 transition hover:scale-[1.15]"
            onClick={() => {
              if (data?.payload && activeIndex === data.payload?.length - 1) {
                swiperRef.current?.slideTo(0);
              } else {
                swiperRef.current?.slideNext();
              }
            }}
          >
            <MdKeyboardArrowUp className="text-[24px] text-darkYellow" />
            <MdKeyboardArrowDown className="text-[24px] text-darkYellow" />
          </div>
        )}
      </div>
    </div>
  );
}

export default TopBarAccountBar;
