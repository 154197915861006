import { ChangeEventHandler } from "react";
import { BiEditAlt } from "react-icons/bi";

const ProfileImageMini = ({
  avatar,
  isEditMode,
  imagePreviewUrl,
  handleChange,
}: {
  avatar: string;
  isEditMode: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  imagePreviewUrl: string;
}) => {
  return (
    <div className="w-fit h-fit relative">
      <div className="h-[73px] w-[73px] rounded-full overflow-hidden">
        {isEditMode ? (
          <img
            src={imagePreviewUrl || avatar || "/eazipro.jpeg"}
            className="w-full h-full "
          />
        ) : (
          <img src={avatar || "/eazipro.jpeg"} className="w-full h-full " />
        )}
      </div>
      {isEditMode && (
        <>
          <label
            htmlFor="upload-photo"
            className={`!block absolute h-[20px] w-[20px] right-[-2px] bottom-[5px]`}
          >
            <div className="flex items-center justify-center bg-darkYellow rounded-full w-[20px] h-[20px] cursor-pointer    ">
              <BiEditAlt className="text-[13px] text-white" />
            </div>
          </label>
          <input
            accept="image/jpeg, image/jpg, image/png"
            type="file"
            name="photo"
            id="upload-photo"
            className="absolute bg-transparent invisible cursor-pointer"
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
};

export default ProfileImageMini;
