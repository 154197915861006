import { FiInbox } from "react-icons/fi";
import { TCustomerData, TDeviceData } from "../../types/types";
import { initialDeviceData } from "../../utils/constants";
import CustomerDeviceBox from "./CustomerDeviceBox";

const mock = [1, 2, 3, 4, 5];
const CustomerDevicesSection = ({
  customerData,
  data,
  isLoading,
}: {
  customerData: TCustomerData;
  data: TDeviceData[];
  isLoading: boolean;
}) => {
  return (
    <div className="customerDevices flex flex-col gap-[14px]">
      {isLoading ? (
        mock.map((datum, index) => {
          return (
            <CustomerDeviceBox
              key={index}
              isLoading={true}
              data={initialDeviceData}
              allDevices={[]}
              customerData={customerData}
            />
          );
        })
      ) : data?.length > 0 ? (
        data.map((datum: TDeviceData, index: number) => {
          return (
            <CustomerDeviceBox
              key={index}
              isLoading={isLoading}
              allDevices={data}
              data={datum}
              customerData={customerData}
            />
          );
        })
      ) : (
        <div className="w-full max-lg:h-[300px] lg:h-[200px] flex items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-2">
            <FiInbox className="text-[#dddddd] text-[35px] " />
            <p className="Nunito text-[#dddddd] font-[400] text-[14px] ">
              No Devices
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDevicesSection;
