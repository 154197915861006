import { Button } from "antd";
import { DeleteSvg } from "../../atoms/svg";
import { useAppStore } from "../../../store/store";
import { MutationKeys, QueryKeys } from "../../../enums/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteDevices } from "../../../services/devices/devices.service";
import { deleteCustomers } from "../../../services/customer/customer.service";
import { deletePaymentPlans } from "../../../services/payment-plans/payment-plan.service";
import {
  deleteDistributor,
  deleteFiatAccountForDistributor,
  deleteUsdcAccountForDistributor,
} from "../../../services/distributor/distributor.service";
import { signOut } from "../../../services/auth/auth.service";

const DeleteModal = () => {
  const modalType = useAppStore((state) => state.modalType);
  const setModalIsOpen = useAppStore((state) => state.setModalIsOpen);
  const setIsDrawerOpen = useAppStore((state) => state.setIsDrawerOpen);
  const modalData = useAppStore((state) => state.modalData);
  const setModalData = useAppStore((state) => state.setModalData);

  const queryClient = useQueryClient();

  const { mutate: deleteDevicesMutate, isPending: deleteDevicesIsPending } =
    useMutation({
      mutationKey: [MutationKeys.DELETEDEVICES],
      mutationFn: () => deleteDevices(modalData),
      onSuccess: (data) => {
        setModalIsOpen(false);
        setIsDrawerOpen(false);
        setModalData([]);
        queryClient.invalidateQueries({
          queryKey: [`${QueryKeys.GETDEVICETABLEDATA}`],
        });
      },
      onError: (error) => {
        // console.log(error);
      },
    });

  const { mutate: deleteCostumersMutate, isPending: deleteCostumersIsPending } =
    useMutation({
      mutationKey: [MutationKeys.DELETECUSTOMERS],
      mutationFn: () => deleteCustomers(modalData),
      onSuccess: (data) => {
        setModalIsOpen(false);
        setIsDrawerOpen(false);
        setModalData([]);
        queryClient.invalidateQueries({
          queryKey: [`${QueryKeys.GETCUSTOMERTABLEDATA}`],
        });
      },
      onError: (error) => {
        // console.log(error);
      },
    });

  const {
    mutate: deletePaymentPlansMutate,
    isPending: deletePaymentPlansIsPending,
  } = useMutation({
    mutationKey: [MutationKeys.DELETEPAYMENTPLANS],
    mutationFn: () => deletePaymentPlans(modalData),
    onSuccess: (data) => {
      setModalIsOpen(false);
      setIsDrawerOpen(false);
      setModalData([]);
      queryClient.invalidateQueries({
        queryKey: [`${QueryKeys.GETPAYMENTPLANSTABLEDATA}`],
      });
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const {
    mutate: deleteDistributorMutate,
    isPending: deleteDistributorIsPending,
  } = useMutation({
    mutationKey: [MutationKeys.DELETEDISTRIBUTOR],
    mutationFn: () => deleteDistributor(),
    onSuccess: (data) => {
      setModalIsOpen(false);
      setIsDrawerOpen(false);
      setModalData([]);
      signOut();
    },
    onError: (error) => {
      // console.log(error);
    },
  });

  const {
    mutate: deleteFiatAccountForDistributorMutate,
    isPending: deleteFiatAccountForDistributorIsPending,
  } = useMutation({
    mutationKey: [MutationKeys.DELETEFIATACCOUNT],
    mutationFn: () => deleteFiatAccountForDistributor(modalData[0]),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETDISTRIBUTORFIATACCOUNTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETAVAILABLEBALANCES],
      });
      setModalIsOpen(false);
      setModalData([]);
    },
  });

  const {
    mutate: deleteUsdcAccountForDistributorMutate,
    isPending: deleteUsdcAccountForDistributorIsPending,
  } = useMutation({
    mutationKey: [MutationKeys.DELETEUSDCACCOUNT],
    mutationFn: () => deleteUsdcAccountForDistributor(modalData[0]),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETDISTRIBUTORUSDCACCOUNTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GETAVAILABLEBALANCES],
      });
      setModalIsOpen(false);
      setModalData([]);
    },
  });

  const getText = () => {
    if (modalType === "deleteCustomers") {
      return { head: "Customers", body: "these customers" };
    } else if (modalType === "deleteCustomer") {
      return { head: "Customer", body: "this customer" };
    } else if (modalType === "deleteDevices") {
      return { head: "Devices", body: "these devices" };
    } else if (modalType === "deleteDevice") {
      return { head: "Device", body: "this device" };
    } else if (modalType === "deletePaymentPlans") {
      return { head: "Payment plans", body: "these payment plans" };
    } else if (modalType === "deletePaymentPlan") {
      return { head: "Payment plan", body: "this payment plan" };
    } else if (modalType === "deleteDistributor") {
      return { head: "Account", body: "your Account" };
    } else if (modalType === "deleteFiatAccount") {
      return { head: "Fiat Account", body: "this fiat account" };
    } else if (modalType === "deleteUsdcAccount") {
      return { head: "Usdc Account", body: "this usdc account" };
    }
  };

  const getDeleteMutate = () => {
    if (modalType === "deleteCustomers" || modalType === "deleteCustomer") {
      return deleteCostumersMutate();
    } else if (modalType === "deleteDevices" || modalType === "deleteDevice") {
      return deleteDevicesMutate();
    } else if (modalType === "deletePaymentPlans") {
      return deletePaymentPlansMutate();
    } else if (modalType === "deleteDistributor") {
      return deleteDistributorMutate();
    } else if (modalType === "deleteFiatAccount") {
      return deleteFiatAccountForDistributorMutate();
    } else if (modalType === "deleteUsdcAccount") {
      return deleteUsdcAccountForDistributorMutate();
    }
  };

  return (
    <div className="w-[370px] h-[280px] bg-white rounded-[8px] shadow ">
      <div className="flex flex-col w-full h-full items-center justify-between p-[30px] ">
        <div className="rounded-full bg-yellow h-[59px] w-[59px] flex items-center justify-center ">
          <DeleteSvg className="w-[33px] h-[33px] " color={"#FFA300"} />
        </div>
        <div className="flex flex-col items-center justify-center gap-[2px] text-center">
          <h5 className="Nunito font-[500] text-[20px] 2xl:text-[24px] text-[#1E1E1E]">
            Delete {getText()?.head}?
          </h5>
          <p className="Nunito font-[300] text-[13px] 2xl:text-[15px] text-[#6B7280]">
            Are you sure you want to delete {getText()?.body}? <br></br>This
            action cannot be undone
          </p>
        </div>
        <div className="flex items-center justify-center w-full gap-[10px] ">
          <Button
            type="primary"
            htmlType="button"
            className="Nunito w-full max-w-[95px] h-[30px] flex items-center justify-center bg-[#EFEFEF] hover:!bg-[#EFEFEF] !text-[#6B7280] hover:!text-[#6B7280] hover:opacity-[0.8] font-[500] text-[10px] 2xl:text-[12px] rounded-[8px]  "
            loading={false}
            onClick={() => {
              setModalIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="button"
            className="Nunito w-full max-w-[95px] h-[30px] flex items-center justify-center bg-darkRed hover:!bg-darkRed !text-white hover:!text-white hover:opacity-[0.8] font-[500] text-[10px] 2xl:text-[12px] rounded-[8px]  "
            loading={
              deleteDevicesIsPending ||
              deleteCostumersIsPending ||
              deletePaymentPlansIsPending ||
              deleteDistributorIsPending ||
              deleteUsdcAccountForDistributorIsPending ||
              deleteFiatAccountForDistributorIsPending
            }
            onClick={() => {
              getDeleteMutate();
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
